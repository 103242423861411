import React from 'react';
import ReactDOM from 'react-dom';
import Draggable from 'react-draggable';

const Modal = ({ children, isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    
    <div style={{ position: 'fixed',justifyContent:'center',top:'300px',width: '27%',height: '35vh', left: '400px', bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0)', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Draggable>
      <div style={{ background: 'white',justifyContent:'center',padding: 20, borderRadius: 5, maxWidth: 500, width: '100%' }}>
        {children}
      </div>
      </Draggable>
    </div>
    ,
    document.getElementById('modal-root')
  );
};

export default Modal;
