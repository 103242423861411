import styled from 'styled-components';


// Estilizando un formulario y un input
/*export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

export const StyledInput = styled.input`
  padding: 5px;
  margin: 5px 0;
  border-radius: 2px;
  border: 1px solid #ccc;
  font-size: 14px;
`;*/


export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;


// Input básico estilizado
export const StyledInput = styled.input`
  padding: 15px;
  margin: 8px 0;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 8px;

  &:focus {
    border-color: #0056b3;
    outline: none;
  }

  &[type="checkbox"] {
    width: auto;
    margin-right: 10px;
    transform: scale(1.5); // Hace el checkbox más grande y fácil de tocar
  }
`;
//ColorPicker,StyledTextArea,StyledInput
// Textarea estilizado
export const StyledTextArea = styled.textarea`
  padding: 15px;
  margin: 8px 0;
  font-size: 16px;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 8px;
  resize: none; // Deshabilitar redimensionamiento

  &:focus {
    border-color: #0056b3;
    outline: none;
  }
`;

// Botón estilizado
export const StyledButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:focus {
    outline: none;
  }
`;

// Selector de color estilizado
export const ColorPicker = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;

  button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 5px;
    border: none;
    cursor: pointer;

    &:focus {
      outline: none;
      border: 2px solid #000;
    }
  }
`;


