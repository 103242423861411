import moment from 'moment-timezone';
//let zonaHoraria = "America/Santiago";

//export const getZonaHoraria = () => zonaHoraria;

/*export const setZonaHoraria = (newTimezone) => {
  zonaHoraria = newTimezone;
  moment.tz.setDefault(zonaHoraria);
};*/

export function convertirFechaUTCALocal(fechaUTC, zonaHoraria) {
  return moment.utc(fechaUTC).tz(zonaHoraria).toDate();
}

export function convertirLocalAUTC(fechaHoraLocal,zonaHoraria) {
     // Creamos un objeto Moment con la fecha y hora local en la zona horaria específica
  const fechaLocalMoment = moment.tz(fechaHoraLocal, zonaHoraria);

  // Convertimos la fecha y hora local a UTC
  const fechaUTC = fechaLocalMoment.clone().utc().toDate();

  // Retornamos la fecha y hora en formato UTC
  console.log("fecha_input,moment,UTC: ",fechaHoraLocal,fechaLocalMoment,fechaUTC);
  return fechaUTC;

}
export function combinarFechaHora(fecha, hora) {
    const fechaHoraISO= new Date(`${fecha}T${hora}Z`);
    const fechaHora = new Date(fechaHoraISO);
    console.log(fechaHora);
    return fechaHora;
  }
/*
  export function combinarFechaHoraUser(fecha, hora) {
    const fechaHoraISO= new Date(`${fecha}T${hora}`);
    const fechaHora = new Date(fechaHoraISO);
    console.log(fechaHora);
    return fechaHora;
  }
  */
 /* export function combinarFechaHoraUser(fecha, hora, zonaHoraria) {
    // Concatenamos la fecha y hora en un formato compatible con Moment.js
    const fechaHoraLocalString = `${fecha}T${hora}`;
  
    // Creamos un objeto Moment con la fecha y hora en la zona horaria proporcionada
    const fechaHoraLocalMoment = moment.tz(fechaHoraLocalString, zonaHoraria);
  
    // Convertimos el objeto Moment a un objeto Date
    const fechaHoraLocalDate = fechaHoraLocalMoment.toDate();
  
    // Retornamos la fecha y hora local como un objeto Date
    return fechaHoraLocalDate;
  }
*/

export function combinarFechaHoraUser(start, hora, zonaHoraria) {
    // Define un formato de entrada claro y comprensible
    const formatoFecha = "YYYY-MM-DD"; // Ejemplo: "2024-04-29"
    const formatoHora = "HH:mm:ss";    // Ejemplo: "08:00:00"
  
    // Combina la fecha y la hora en una sola cadena
    const dateTimeString = `${start} ${hora}`;
  
    // Crea un objeto moment en la zona horaria específica
    const dateTimeMoment = moment.tz(dateTimeString, `${formatoFecha} ${formatoHora}`, zonaHoraria);
  
    // Formatea la fecha para el formato ISO sin segundos
    return dateTimeMoment.format('YYYY-MM-DDTHH:mm');
  }
/*export function combinarFechaHoraUser(fecha, hora, zonaHoraria) {
    // Concatenamos la fecha y hora en un formato compatible con Moment.js
    const fechaHoraLocalString = `${fecha}T${hora}`;
  
    // Creamos un objeto Moment con la fecha y hora local en la zona horaria proporcionada
    const fechaHoraLocalMoment = moment.tz(fechaHoraLocalString, "YYYY-MM-DDTHH:mm", zonaHoraria);
  
    // Retornamos el objeto Moment
    return fechaHoraLocalMoment;
  }*/
 export function separarFechaHora(fechaHora) {
    const fecha = fechaHora.toISOString().slice(0, 10);  // YYYY-MM-DD
    const hora = fechaHora.toTimeString().slice(0, 8);  // HH:MM:SS
    return { fecha, hora };
  }

  // Función para combinar fecha y hora y convertirlas a UTC
export function convertirAUTC(localDateString, localTimeString, localTimeZone) {
    // Formato de fecha y hora
    const formato = "YYYY-MM-DD HH:mm:ss";
  
    // Combina la fecha y la hora en una sola cadena en la zona horaria local
    const dateTimeLocal = moment.tz(`${localDateString} ${localTimeString}`, formato, localTimeZone);
  
    // Convierte el objeto de momento a la hora UTC
    const dateTimeUTC = dateTimeLocal.tz('UTC');
  
    // Devuelve la fecha y hora en formato ISO o cualquier formato que necesites
    return dateTimeUTC.format('YYYY-MM-DDTHH:mm:ss') + 'Z'; // Añade 'Z' para indicar que es UTC
  }
  
 export function separarFechaHoraISO(fechaISO) {
    const fechaObj = new Date(fechaISO);
    
    // Extraer la fecha en formato YYYY-MM-DD
    const fecha = fechaObj.toISOString().split('T')[0];
  
    // Extraer la hora en formato HH:mm:ss
    const hora = fechaObj.toISOString().split('T')[1].slice(0, 8);
  
    return { fecha, hora };
  }