import React, { useState,useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {TagSelectorComponent} from './TagSelector';


// Estilos para el formulario
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Input = styled.input`
  padding: 10px;
  border: 2px solid #ddd;
  border-radius: 5px;
  font-size: 16px;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const TextArea = styled.textarea`
  padding: 10px;
  border: 2px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  resize: vertical;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;
/*
const Button = styled.button`
  padding:  0.8rem 4rem;
  margin-right: 0.5rem;
  margin: 0 0.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f0f0f0;
  &:hover {
    background-color: #e2e2e2;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
`;*/
const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Permite que los botones se ajusten según el espacio disponible */
  justify-content: space-between; /* Distribuye el espacio uniformemente entre los botones */
  gap: 10px; /* Añade un pequeño espacio entre los botones para evitar que se toquen */
  padding: 1rem 0; /* Añade algo de relleno arriba y abajo del contenedor de botones */
`;
const Button = styled.button`
  padding: 0.8rem 1.5rem; /* Ajusta el padding para que sea adecuado para textos cortos y largos */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f0f0f0;
  flex-grow: 1; /* Permite que los botones crezcan para llenar el espacio disponible si es necesario */

  &:not(:last-child) {
    margin-right: 10px; /* Añade margen a la derecha de todos los botones excepto el último */
  }

  &:hover {
    background-color: #e2e2e2;
  }
`;


const Select = styled.select`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
`;


// Componente TaskForm
const TaskForm = ({ onSaveTask,onCancel,onDelete,onUpdateTask,userName,userId,InitialTask = null }) => {


  const [fecha_inicio, setfecha_inicio] = useState(new Date());
  const { t } = useTranslation();
  const [title, setTitle] = useState(InitialTask ? InitialTask.titulo: '' || '');
  const [description, setDescritpion] = useState(InitialTask ? InitialTask.descripcion: '' || '');
  const [estado, setEstado] = useState(InitialTask ? InitialTask.estado: 'Pendiente' || 'Pendiente');
  const [selectedTags, setSelectedTags] = useState(InitialTask ? InitialTask.selectedTags:[]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const titulo = formData.get('titulo');
    const descripcion = formData.get('descripcion');
    const estado = formData.get('estado');
    const usuario_asignado_id = userId;
    
    if (InitialTask){
      onUpdateTask({titulo, descripcion, fecha_inicio, estado, usuario_asignado_id,id:InitialTask.id,selectedTags});

    } else
    {
      onSaveTask({ titulo, descripcion, fecha_inicio, estado, usuario_asignado_id,selectedTags });
    }
  };
  const handleMouseEvent = (event) => {
    event.preventDefault();
    event.stopPropagation();
    // Lógica adicional aquí
  };
  
  useEffect(() => {
    console.log("que recibe de task el useefecct del taskform?",InitialTask);
    setTitle(InitialTask ? InitialTask.titulo: '' || '');
    setDescritpion(InitialTask ? InitialTask.descripcion: '' || '');
  }, [InitialTask]);

  return (
    <Form onSubmit={handleSubmit}>
      <Input name="titulo" value ={title} onChange = {e => setTitle(e.target.value)} placeholder={t('name')} required />
      <TextArea name="descripcion" value={description} onChange = {e => setDescritpion(e.target.value)} placeholder={t('description')} rows="4" required />
      {/*<Input type="datetime-local" name="fecha_inicio" required />
      <Input type="datetime-local" name="fecha_fin" required />*/}
       <Select name="estado" value={estado} onChange = {e => setEstado(e.target.value)} required>
      <option value="pendiente">{t("pendiente")}</option>
      <option value="en curso">{t("en curso")}</option>
      <option value="completado">{t("completado")}</option>
    </Select>
    
    {/*<label>Tags</label>*/}
      <TagSelectorComponent
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />
  
      {/*<Input type="number" name="usuario_asignado_id" placeholder="ID Usuario Asignado" required />
      <Input type="number" name="id_videoconferencia" placeholder="ID Videoconferencia (Opcional)" />*/}
      <ButtonContainer>
      <Button type="submit">
        {InitialTask? t('update'):t('save')}
      </Button>
      {InitialTask && (
        <Button type="button" onClick={onDelete} style={{backgroundColor: '#ff4d4f', color: 'white'}}>{t('delete')}</Button>
      )}
      <Button type="button" onClick={onCancel} style={{backgroundColor: '#ff4d4f', color: 'white'}}>{t('cancel')}</Button>
      </ButtonContainer>
    </Form>

  );
};

export default TaskForm;