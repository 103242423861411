import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';

const MiniMapContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 158px;
  height: 224px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #ccc;
  overflow: hidden;
  cursor: grab; /* Añade cursor de agarre para indicar interactividad */
`;

const Viewport = styled.div`
  position: absolute;
  border: 2px solid red;
  width: 50px; /* Ancho original del cuadro rojo */
  height: 50px; /* Alto original del cuadro rojo */
  pointer-events: none; /* Asegura que no interfiera con eventos de mouse */
`;

const MiniMap = ({ scrollPosition, setScrollPosition, canvasSize, miniMapSize, backgroundImage }) => {
    const viewportRef = useRef(null);
    const miniMapRef = useRef(null);
    const isProgrammaticScroll = useRef(false);

    useEffect(() => {
      const updateViewportPosition = () => {
        if (viewportRef.current && miniMapRef.current) {
          const { width, height } = miniMapRef.current.getBoundingClientRect();
          const scaleX = width / canvasSize.width;
          const scaleY = height / canvasSize.height;

          viewportRef.current.style.width = `${scaleX * window.innerWidth}px`;
          viewportRef.current.style.height = `${scaleY * window.innerHeight}px`;
          viewportRef.current.style.transform = `translate(${scaleX * scrollPosition.x}px, ${scaleY * scrollPosition.y}px)`;
        }
      };

      updateViewportPosition();
      const handleScroll = () => {
        if (!isProgrammaticScroll.current) {
          updateViewportPosition();
        }
        isProgrammaticScroll.current = false; // Reset the flag
      };

      // Subscribe to scroll events from the parent panel
      const panel = document.getElementById('panel-container');
      panel.addEventListener('scroll', handleScroll);

      return () => {
        panel.removeEventListener('scroll', handleScroll);
      };
    }, [scrollPosition, canvasSize, miniMapSize]);

    const handleMouseDown = (event) => {
      event.preventDefault();
      miniMapRef.current.style.cursor = 'grabbing';
      const startX = event.clientX;
      const startY = event.clientY;
      const startScroll = { ...scrollPosition };

      const doDrag = (moveEvent) => {
        const dx = moveEvent.clientX - startX;
        const dy = moveEvent.clientY - startY;
        const { width, height } = miniMapRef.current.getBoundingClientRect();

        const scaleX = canvasSize.width / width;
        const scaleY = canvasSize.height / height;

        const newScrollX = Math.max(0, Math.min(canvasSize.width - window.innerWidth, startScroll.x + dx * scaleX));
        const newScrollY = Math.max(0, Math.min(canvasSize.height - window.innerHeight, startScroll.y + dy * scaleY));

        isProgrammaticScroll.current = true;
        setScrollPosition({ x: newScrollX, y: newScrollY });
      };

      const stopDrag = () => {
        document.removeEventListener('mousemove', doDrag);
        document.removeEventListener('mouseup', stopDrag);
        miniMapRef.current.style.cursor = 'grab';
      };

      document.addEventListener('mousemove', doDrag);
      document.addEventListener('mouseup', stopDrag);
    };

    return (
      <MiniMapContainer
        style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }}
        ref={miniMapRef} 
        onMouseDown={handleMouseDown}>
        <Viewport ref={viewportRef} />
      </MiniMapContainer>
    );
};

export default MiniMap;

/*import React, { useRef, useEffect ,useState} from 'react';
import styled from 'styled-components';

const MiniMapContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 158px;
  height: 224px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #ccc;
  overflow: hidden;
`;

const Viewport = styled.div`
  position: absolute;
  border: 2px solid red;
  cursor: grab;
  width: 50px; 
  height: 50px; 
`;

const MiniMap = ({ scrollPosition, setScrollPosition, canvasSize, miniMapSize, backgroundImage }) => {
    const [isDragging, setIsDragging] = useState(false);
    const viewportRef = useRef(null);
    const miniMapRef = useRef(null);
   
      
  
    // Ajustar la posición del cuadro rojo basado en scrollPosition
    useEffect(() => {
      if (viewportRef.current && miniMapRef.current) {
        const { width, height } = miniMapRef.current.getBoundingClientRect();
        const scaleX = width / canvasSize.width;
        const scaleY = height / canvasSize.height;
  
        viewportRef.current.style.width = `${scaleX * window.innerWidth}px`;
        viewportRef.current.style.height = `${scaleY * window.innerHeight}px`;
        viewportRef.current.style.transform = `translate(${scaleX * scrollPosition.x}px, ${scaleY * scrollPosition.y}px)`;
      }
      console.log("url backgoundImage: ",backgroundImage);
    }, [scrollPosition, canvasSize, miniMapSize]);
  
    const handleMouseDown = (event) => {
      setIsDragging(true);
      event.preventDefault();
    };
  
    const handleMouseMove = (event) => {
      if (isDragging && viewportRef.current && miniMapRef.current) {
        const { left, top, width, height } = miniMapRef.current.getBoundingClientRect();
        const x = Math.max(0, Math.min(width - viewportRef.current.offsetWidth, event.clientX - left));
        const y = Math.max(0, Math.min(height - viewportRef.current.offsetHeight, event.clientY - top));
  
        const scaleX = canvasSize.width / width;
        const scaleY = canvasSize.height / height;
  
        setScrollPosition({ x: x * scaleX, y: y * scaleY });
  
        viewportRef.current.style.transform = `translate(${x}px, ${y}px)`;
      }
    };
  
    const handleMouseUp = () => {
      setIsDragging(false);
    };
  
    return (
      <MiniMapContainer style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }}
        ref={miniMapRef} onMouseMove={handleMouseMove} onMouseLeave={handleMouseUp} onMouseUp={handleMouseUp}>
        <Viewport ref={viewportRef} onMouseDown={handleMouseDown} />
      </MiniMapContainer>
    );
  };
  
  export default MiniMap;*/


/*import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

const MiniMapContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 79px;
  height: 112px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #ccc;
  overflow: hidden;
`;

const Viewport = styled.div`
  position: absolute;
  border: 2px solid red;
  cursor: grab;
`;

const MiniMap = ({ scrollPosition, setScrollPosition, canvasSize, miniMapSize }) => {
  const miniMapRef = useRef(null);
  const viewportRef = useRef(null);

  useEffect(() => {
    const updateViewportPosition = () => {
      const viewport = viewportRef.current;
      if (!viewport) return;

      const scaleWidth = miniMapSize.width / canvasSize.width;
      const scaleHeight = miniMapSize.height / canvasSize.height;

      const viewRectWidth = window.innerWidth * scaleWidth;
      const viewRectHeight = window.innerHeight * scaleHeight;
      const x = scrollPosition.x * scaleWidth;
      const y = scrollPosition.y * scaleHeight;

      viewport.style.width = `${viewRectWidth}px`;
      viewport.style.height = `${viewRectHeight}px`;
      viewport.style.transform = `translate(${x}px, ${y}px)`;
    };

    updateViewportPosition();
  }, [scrollPosition, canvasSize, miniMapSize]);

  const handleMouseDown = (event) => {
    const startDragging = (event) => {
      const miniMapRect = miniMapRef.current.getBoundingClientRect();
      const x = (event.clientX - miniMapRect.left);// / ((miniMapRect.width) * canvasSize.width);
      const y = (event.clientY - miniMapRect.top);// / ((miniMapRect.height) * canvasSize.height);
      console.log("Posición del Scroll:",scrollPosition.x,scrollPosition.y );
      console.log("Posición del evento arrastar:",x,y );

      setScrollPosition({
        x: x - window.innerWidth / 2,
        y: y - window.innerHeight / 2
      });
    };

    document.addEventListener('mousemove', startDragging);
    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', startDragging);
    }, { once: true });
  };

  const handleMouseMove = (event) => {
    if (event.buttons === 1) { // only if mouse button is held down
      handleMouseDown(event);
    }
  };
  

  return (
    <MiniMapContainer ref={miniMapRef} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove}>
      <Viewport ref={viewportRef} />
    </MiniMapContainer>
  );
};

export default MiniMap;*/


