import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import './TimeFitHomePage.css';
import { useTranslation } from 'react-i18next';
import backgroundImage from './images/backgroundImage.webp'; // Replace with the actual file name if different
import officeImage from './images/officeImage.webp'; // Replace with the actual file name if different
import virtualMeetingImage from './images/virtualMeetingImage.webp'; // Replace with the actual file name if different
import timeManagementImage from './images/timeManagementImage.webp'; // Replace with the actual file name if different


const LandingPage = () => {
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation();



  return (
    <div className="landingPage">
      <nav className="navbar">
        <div className="logo">{t('timeFIT')}</div>
        <div className="nav-links">
          <a href="#features">{t('features')}</a>
          <a href="#about">{t('about')}</a>
          <a href="#" onClick={() => loginWithRedirect()}>{t('signIn')}</a>
        </div>
      </nav>

      <header className="header" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <h1>{t('welcomeToTimeFIT')}</h1>
        <p>{t('tagline')}</p>
        <button onClick={() => loginWithRedirect()}>{t('getStarted')}</button>
      </header>

      <main>
        <section id="features">
          <h2>{t('keyFeatures')}</h2>
          <div className="features-grid">
            <div className="feature">
              <h3>{t('overcomingDistractions')}</h3>
              <p>{t('overcomingDistractionsDescription')}</p>
              <img src={officeImage} alt="Office" />
            </div>
            <div className="feature">
              <h3>{t('powerOfTimeBoxing')}</h3>
              <p>{t('powerOfTimeBoxingDescription')}</p>
              <img src={virtualMeetingImage} alt="Virtual Meeting" />
            </div>
            <div className="feature">
              <h3>{t('myPersonalTransformation')}</h3>
              <p>{t('myPersonalTransformationDescription')}</p>
              <img src={timeManagementImage} alt="Time Management" />
            </div>
          </div>
        </section>

        <section id="about">
          <h2>{t('aboutTimeFIT')}</h2>
          <p>{t('aboutDescription')}</p>
        </section>
      </main>

      <footer>
        <p>{t('followUs')}</p>
      </footer>
    </div>
  );
};

export default LandingPage;
