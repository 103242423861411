import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';



// Asume que tienes una función que puede calcular el estado de estilo original
// de tus elementos basado en el nivel de zoom de 1.
function adjustForPDF(zoomLevel) {
  const panelContainer = document.getElementById('panel-container');
  if (!panelContainer) return;

  // Ajusta el PanelContainer y sus elementos internos
  const originalZoom = zoomLevel; // Guarda el zoom level original
  const scaleAdjustment = 1 / originalZoom; // Calcula el ajuste necesario para escalar a zoomLevel = 1

  // Ajusta el tamaño y posición del contenedor basado en zoomLevel = 1
  panelContainer.style.transform = `scale(${scaleAdjustment})`;
  
  // Ajustar elementos internos si es necesario
  // Por ejemplo, si tienes elementos que su tamaño o posición depende directamente del zoomLevel,
  // deberás ajustarlos aquí también.

  return () => {
    // Revertir ajustes aquí
    panelContainer.style.transform = ''; // o cualquier valor original
    // Revertir ajustes de elementos internos si hiciste cambios
  };
}

export const SavePDF = async (elements,zoomLevel) => {
  const pdf = new jsPDF('portrait', 'mm', 'a4');
  const DPI = 96;
  const mmPerInch = 25.4;
  const pixelToMm = (pixels) => (pixels / DPI) * mmPerInch;

  // Asumiendo que 'elements' es un arreglo de objetos con las propiedades { ref, position, size, ... }
  for (let element of elements) {

    const input = document.getElementById(element.id);
    if (!input) {
      console.error('El elemento no se encuentra o aún no está disponible.');
      return;
    }
    const canvas = await html2canvas(input, {
      scale: 1, // Considera el nivel de zoom aquí si es necesario
      backgroundColor: null,
      useCORS: true,
    });

    const imgData = canvas.toDataURL('image/png');
    if (!imgData) {
      console.error('no se puede generar imagane de ', canvas);
      return;
    }

      // Calcula el ancho y alto del PDF en base al tamaño del canvas y el formato A4
    const imgWidth = 210; // Ancho de un A4 en mm
    const pageHeight = 295;  // Alto de un A4 en mm
    const imgHeight = canvas.height * imgWidth / canvas.width;
    let heightLeft = imgHeight;
    // Ajusta las dimensiones y posiciones de los elementos según el zoomLevel y las dimensiones de A4

    const scaleFactor = 1/zoomLevel; // Este es un ejemplo, ajusta esto según tus necesidades
    const adjustedWidth = pixelToMm(canvas.width * scaleFactor);
    const adjustedHeight = pixelToMm(canvas.height * scaleFactor);
    const adjustedX = pixelToMm(element.position.x );
    const adjustedY = pixelToMm(element.position.y );
    console.log("x,y,ancho,alto: ",adjustedX, adjustedY, adjustedWidth, adjustedHeight);

    // Asegúrate de que los elementos ajustados quepan en la página A4
    // Puedes necesitar lógica adicional aquí para manejar elementos que excedan las dimensiones de A4
    // Crea una nueva instancia de jsPDF en orientación vertical
    let position = {adjustedX, adjustedY};

    // Añade la imagen al documento
    pdf.addImage(imgData, 'PNG', adjustedX, adjustedY, adjustedWidth, adjustedHeight);

    /*
    heightLeft -= pageHeight;

    // En caso de que el contenido exceda una página, agrega nuevas páginas y continua agregando el contenido
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, 'PNG', 0, position, adjustedWidth, adjustedHeight);
      heightLeft -= pageHeight;
    }
    */
  }

  // Guardar el PDF
  pdf.save('document.pdf');

}
export const generateMiniMapImage = async (panelRef) => {
  try {
    const canvas = await html2canvas(panelRef.current, {
      scale: 0.4, // Ajusta esta escala según la relación de tamaño deseada para el mini mapa
      useCORS: true
    });

    const dataUrl = canvas.toDataURL('image/png');
    return dataUrl;
  } catch (error) {
    console.error('Error generating mini map image:', error);
    return null;
  }
};




// Función para generar el PDF
const generatePDF = async (zoomLevel) => {
  const revertAdjustments = adjustForPDF(zoomLevel);
  // Asume que tienes un div con el id 'panel-container' que quieres convertir a PDF
  const input = document.getElementById('panel-container');
  if (!input) {
    console.error('El elemento no se encuentra o aún no está disponible.');
    return;
  }
  const pdf = new jsPDF('portrait', 'mm', 'a4');

// Captura el contenido del div con html2canvas
  const canvas = await html2canvas(input);
  const imgData = canvas.toDataURL('image/png',1.0);

  // Calcula el ancho y alto del PDF en base al tamaño del canvas y el formato A4
  const imgWidth = 210; // Ancho de un A4 en mm
  const pageHeight = 295;  // Alto de un A4 en mm
  const imgHeight = canvas.height * imgWidth / canvas.width;
  let heightLeft = imgHeight;

  // Crea una nueva instancia de jsPDF en orientación vertical
  let position = 0;

  // Añade la imagen al PDF
  pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
  heightLeft -= pageHeight;

  // En caso de que el contenido exceda una página, agrega nuevas páginas y continua agregando el contenido
  while (heightLeft >= 0) {
    position = heightLeft - imgHeight;
    pdf.addPage();
    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
  }
  

  // Guarda el PDF con un nombre de archivo específico
  pdf.save('panel-worksheet.pdf');
  revertAdjustments();
};

