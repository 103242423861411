/*import styled from 'styled-components';

// Creando un botón estilizado
const StyledButton = styled.button`
  padding: ${({ size }) => (size === 'small' ? '5px 10px' : '10px 20px')};
  font-size: ${({ size }) => (size === 'small' ? '14px' : '16px')};
  border-radius: ${({ size }) => (size === 'small' ? '3px' : '5px')};
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  margin-right: 2px; // Añade un margen a la derecha de cada botón

  &:hover {
    background-color: #0056b3;
  }
  &:last-child {
    margin-right: 0; // Asegura que el último botón no tenga margen a la derecha
  }
`;

export default StyledButton;*/
/*import styled from 'styled-components';

const StyledButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  margin-right: 2px; // Añade un margen a la derecha de cada botón

  &:hover {
    background-color: #0056b3;
  }
  &:last-child {
    margin-right: 0; // Asegura que el último botón no tenga margen a la derecha
  }

  // Media queries para ajustes en dispositivos móviles
  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 3px;
  }
`;

export default StyledButton;
*/
import styled, { css } from 'styled-components';

// Define a mixin for the disabled style
const disabledStyle = css`
  background-color: #ccc;
  cursor: not-allowed;
  /* Add any other styles you want for disabled buttons */
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  margin-right: 2px;

  &:hover {
    background-color: #0056b3;
  }

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 3px;
  }

  /* Apply the disabled style when the button is disabled */
  ${props => props.disabled && disabledStyle}
`;

export default StyledButton;
