import supabase from './supabaseClient';

// Añadir una nueva tarea
export const addTask = async (task) => {
    const { data, error } = await supabase.from('tasks').insert([task]).select();
    if (error) throw error;
    return data[0];
};

// Obtener todas las tareas para un usuario específico
/*export const getTasks = async (userId) => {
    const { data, error } = await supabase.from('tasks').select('*').eq('usuario_asignado_id', userId);
    if (error) throw error;
    console.log("que data trae Tasks",data);
    return data;
};*/

export const getTasks = async (userId) => {
  const { data, error } = await supabase
        .from('tasks')
        .select(`
            *,
            tasktags!left (
                tag_id,
                tags!tasktags_tag_id_fkey (
                    tag_id,
                    tag_name,
                    color
                )
            )
        `)
        .eq('usuario_asignado_id', userId);

  if (error) throw error;
  console.log("Datos de tareas con tags:", data);
  return data.map(task => {
      // Transforma los datos para incluir los tags en un formato más amigable
      return {
          ...task,
          selectedTags: (task.tasktags || []).map(tt => (tt.tag_id))
      };
  });
};


// Actualizar una tarea existente
export const updateTask = async (id, updatedTask) => {
    const { data, error } = await supabase
        .from('tasks')
        .update(updatedTask)
        .match({ id })
        .select();
    if (error) throw error;
    return data;
};

// Eliminar una tarea
/*export const deleteTask = async (id) => {
  await deleteTaskElements(id);
    const { data, error } = await supabase
        .from('tasks')
        .delete()
        .match({ id });
    if (error) throw error;
    return data;
};
*/
export const deleteTask = async (id) => {
  try {
    // Primero, elimina los elementos de la tarea
    await deleteTaskElements(id);
    await deleteTaskTasks(id);

    // Luego, elimina la tarea
    const { data, error } = await supabase.from('tasks').delete().match({ id: id });
    if (error) {
      console.error("Error eliminando la tarea:", error);
      throw error;
    }
    console.log("Tarea eliminada:", data);
    return data;
  } catch (error) {
    console.error("Error en la operación de eliminación:", error);
    // Considera manejar este error de manera que la UI pueda reflejar el problema
  }
};

// Obtener una tarea por ID
export const getTaskById = async (taskId) => {
    const { data, error } = await supabase
        .from('tasks')
        .select('*')
        .eq('id', taskId)
        .single();
    if (error) throw error;
    return data;
};




export const saveTaskElement = async (element) => {
  const { data, error } = await supabase
    .from('task_elements')
    .insert([{
      task_id: element.taskId,
      type: element.type,
      position_x: element.position.x,
      position_y: element.position.y,
      size_width: element.size.width,
      size_height: element.size.height,
      content: element.content,
      font_size: element.fontSize,
      color: element.color,
    }]);

  if (error) {
    console.error('Error saving task element:', error);
    return null;
  }
  return data;
};

// Eliminar todos los elementos de una tarea específica
const deleteTaskElements = async (taskId) => {
  const { error } = await supabase
    .from('task_elements')
    .delete()
    .match({ task_id: taskId });

  if (error) throw error;
};

const deleteTaskTasks= async (taskId) => {
  const { error } = await supabase
    .from('tasktags')
    .delete()
    .match({ task_id: taskId });

  if (error) throw error;
};

// Guardar los elementos de la tarea actual
export const saveCurrentTaskElements = async (taskId, elements) => {
  try {
    console.log("taskId: ",taskId);
    // Primero, eliminar todos los elementos existentes de la tarea
    await deleteTaskElements(taskId);

    // Luego, agregar los nuevos elementos uno por uno
    // Podrías optimizar esto para hacer un único insert si lo prefieres
    for (const element of elements) {

      console.log("elemento: ",element);
      await saveTaskElement({ ...element, taskId });
    }
    console.log("Todos los elementos han sido actualizados exitosamente.");
  } catch (error) {
    console.error('Error al guardar los elementos de la tarea:', error);
  }
};

export const getTaskElements = async (taskId) => {
  if (!taskId)
  {
    return [];
  }
  console.log("getTaskelements: ",taskId);
  try {
    // Realiza la consulta a la tabla 'task_elements' donde el 'task_id' coincide con el taskId proporcionado
    const { data, error } = await supabase
      .from('task_elements')
      .select('*')
      .eq('task_id', taskId);

    if (error) {
      throw error;
    }
    const adaptedElements = data.map((element) => ({
      ...element,
      position: { x: element.position_x, y: element.position_y },
      size: { width: element.size_width, height: element.size_height },
      // Suponiendo que también necesitas adaptar otras propiedades como color, contenido, etc.
      color: element.color,
      content: element.content,
      fontSize: element.font_size,
    }));
    console.log("elementos cargado:", adaptedElements)
    return adaptedElements;
  } catch (error) {
    console.error('Error fetching task elements:', error.message);
    // Maneja el error según sea necesario en tu aplicación
    return []; // Devuelve un array vacío en caso de error para mantener la consistencia del tipo de retorno
  }
};


export const uploadImageToBucket = async (file) => {
  if (!file) return { error: 'No file provided' };

  const fileExt = file.name.split('.').pop();
  const fileName = `${Date.now()}.${fileExt}`;
  const filePath = `${fileName}`;

  const { data, error } = await supabase.storage
      .from('TimeFitB')
      .upload(filePath, file);

  if (error) {
      console.error('Error uploading file:', error.message);
      return { error };
  }

  //console.log("la data devuelta en el uplad es: ",data);
  const newPath = data.path;
  // Obtiene la URL pública de la imagen subida

  
  const { data:publicURL,error: storageError } = await supabase.storage
      .from('TimeFitB')
      .getPublicUrl(newPath);
    if (storageError) {
        console.error('Error leyendo la URL de la imagen:', storageError.message);
        return { storageError };
    }
    const UrlPublica= publicURL.publicUrl;
    //console.log('URL publica,publicURL:', publicURL);
    //console.log('URL publica:', UrlPublica);
  return { data, publicURL,error };
};

//export const deleteImage { data, error } = await supabase.storage.from('your-bucket-name').remove(['path/to/your/file']);

// Eliminar todos los elementos de una tarea específica
export const deleteImage = async (url) => {
  console.log("Eliminación image url: ",url);
  const filePath = url.split('/').pop();
  console.log("Eliminación image path: ",filePath);
  const { data,error } = await supabase.storage
    .from('TimeFitB')
    .remove(filePath)
  if (error) throw error;
  console.log(data,error);
};

export const guardarMensaje = async (userId, mensaje, emisor) => {
  const { data, error } = await supabase
    .from('chat_history')
    .insert([
      { user_id: userId, message: mensaje, sender: emisor }
    ]);

  if (error) {
    console.error('Error al guardar el mensaje:', error.message);
  }
};

export const obtenerHistorialChat = async (userId) => {
  const { data, error } = await supabase
    .from('chat_history')
    .select('message, sender')
    .eq('user_id', userId)
    .order('created_at', { ascending: false })
    .limit(10); // Ajusta el límite según lo que consideres adecuado

  if (error) {
    console.error('Error al recuperar el historial de chat:', error.message);
    return [];
  }

  return data;
};


export const saveTaskWithTags = async (task) => {
  console.log("task: ",task);
  const { selectedTags, ...taskDetails } = task;
console.log("task detaila: ",taskDetails);

  const { data: taskData, error: taskError } = await supabase
    .from('tasks')
    .insert([taskDetails])
    .select();

  if (taskError) throw new Error(taskError.message);

  const taskId = taskData[0].id;
  const taskTags = selectedTags.map(tagId => ({
    task_id: taskId,
    tag_id: tagId
  }));

  const { error: taskTagsError } = await supabase
    .from('tasktags')
    .insert(taskTags);

  if (taskTagsError) throw new Error(taskTagsError.message);

  return taskData;
};


export const updateTaskWithTags = async (taskId, updatedTask) => {
  const { selectedTags, ...taskDetails } = updatedTask;

  const { data, error: taskError } = await supabase
    .from('tasks')
    .update(taskDetails)
    .match({ id: taskId });

  if (taskError) throw new Error(taskError.message);

  // Eliminar todos los tags actuales
  const { error: deleteError } = await supabase
    .from('tasktags')
    .delete()
    .match({ task_id: taskId });

  if (deleteError) throw new Error(deleteError.message);
  console.log("tags seleccionados: ",selectedTags[0])

  if (!Array.isArray(selectedTags)) {
    console.error("selectedTags no es un array:", selectedTags);
  }
  

  // Insertar nuevos tags
  const taskTags = selectedTags.map(tagId => ({
    task_id: taskId,
    tag_id: tagId
  }));

  const { error: taskTagsError } = await supabase
    .from('tasktags')
    .insert(taskTags);

  if (taskTagsError) throw new Error(taskTagsError.message);

  return updatedTask;
};


export const getTaskWithTags = async (taskId) => {
  const { data, error } = await supabase
    .from('tasks')
    .select(`
      *,
      tasktags!inner(
        tag_id,
        tags (
          tag_name,
          color
        )
      )
    `)
    .eq('id', taskId);

  if (error) throw new Error(error.message);

  return data;
};


export const fetchTags = async (userId) => {
  const { data, error } = await supabase
      .from('tags')
      .select('*')
      .or(`id_usuario.eq.${userId},id_usuario.is.null`);

  if (error) {
      console.error('Error fetching tags:', error);
      return [];
  }
  return data;
};