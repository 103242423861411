// SidebarStyles.js
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

export const SidebarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 300px;
  background-color: #ffffff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transform: ${({ isVisible }) => (isVisible ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export const UserSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

export const ConfigSection = styled.div`
  margin-bottom: 20px;
`;

export const NavSection = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledButton = styled(Button)`
  background-color: #007bff;
  color: white;
  &:hover {
    background-color: #0056b3;
  }
`;

export const LogoutSection = styled.div`
  margin-top: auto;
  padding-top: 20px;
  border-top: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const Version = styled.div`
  font-size: 14px;
  color: #999;
`;
