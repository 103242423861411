import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import Modal from 'react-modal'; // Importa `react-modal`
import { MobileProvider } from './MobileContext';
import { UserProvider } from './components/User/UserContext'; 

Modal.setAppElement('#root');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Auth0Provider
    domain="dev-5igrrah5nbbyspdl.us.auth0.com"
    clientId="qeTXj2svhTCPJROGLfFPttBkthNKajrr"
    redirectUri={window.location.origin}
  >
<MobileProvider>
<UserProvider>
<App />
</UserProvider>
</MobileProvider>
  </Auth0Provider>,

  </React.StrictMode>
);

//  document.getElementById('root')
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/*


ReactDOM.render(
  <Auth0Provider
    domain="dev-5igrrah5nbbyspdl.us.auth0.com"
    clientId="qeTXj2svhTCPJROGLfFPttBkthNKajrr"
    redirectUri={window.location.origin}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
  
);
*/