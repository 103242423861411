import React, { useState, useEffect,useRef } from 'react';
import moment from 'moment';
import './EventForm.css';
import { addEvent,insertEventWithSeries} from '../api/eventsApi'; 
import TextEditorModal from './EditorTexto2';
import DOMPurify from 'dompurify';
import SerieConfig from './SerieConfig';
import { format } from 'date-fns';
import Header from '../design/Header';
import {StyledInput,StyledForm,StyledTextArea} from '../design/InputForm';
import StyledButton from '../design/StyledButton';
import { useTranslation } from 'react-i18next';
import Draggable from 'react-draggable';
import { convertirAUTC, separarFechaHoraISO,convertirLocalAUTC } from '../Idioma/DateUtils.js'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ContentState, convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useUserContext } from '../User/UserContext.js';

const customStyles = {
  input: {
    fontFamily: 'Roboto',
    fontSize: "20px",
    color: "black",
    width: "98%",
    cursor:"pointer"
  },
};
const convertBlocksToHtml = (descriptionJson) => {
  try {
    // Inicializa una variable para almacenar el HTML resultante
    let htmlContent = '';

    // Iterar sobre los bloques y tomar solo el texto
    descriptionJson.blocks.forEach((block) => {
      const sanitizedText = DOMPurify.sanitize(block.text);
      htmlContent += `<p>${sanitizedText}</p>`; // Envuelve el texto en etiquetas <p>
    });

    return htmlContent;
  } catch (error) {
    console.error('Error al construir el HTML:', error);
    return ''; // Retorna un HTML vacío en caso de error
  }
};

const convertToHTML = (description) => {
  try {
    console.log("description: ",description);
    // Intenta interpretar el contenido como JSON
    const parsedContent = JSON.parse(description);
    const htmlContent = convertBlocksToHtml(parsedContent);
    //console.log("parsedContent: ",parsedContent);
    //const contentState = convertFromRaw(description);
    //console.log("contentState: ",contentState);
    //const htmlContent = draftToHtml(contentState);
    //const htmlContent = convertBlocksToHtml(contentState);
    console.log("htmlContent: ",htmlContent);
    return DOMPurify.sanitize(htmlContent);
  } catch (jsonError) {
    // Si no es JSON, intenta interpretarlo como HTML
    try {
      htmlToDraft(description); // Validación del HTML
      return DOMPurify.sanitize(description); // Devuelve el HTML limpio
    } catch (htmlError) {
      console.error('Error al interpretar el contenido:', htmlError);
      return ''; // Retorna un contenido vacío en caso de error
    }
  }
};
//import TextEditorModal from './TextEditorModal'; // Asegúrate de usar la ruta correcta



//import MarkdownEditor from '@uiw/react-markdown-editor';



function transformSerieConfigToDBFormat(serieConfig,timefitUser) {
  //const fecha_inicioc = convertirLocalAUTC(combinarFechaHoraUser(serieConfig.startDate,serieConfig.startTime,zonaHoraria),zonaHoraria);
  //const fecha_finc = convertirLocalAUTC(combinarFechaHoraUser(serieConfig.endDate,serieConfig.endTime,zonaHoraria),zonaHoraria);
  /*const {fecha:fecha_inicio, hora:hora_inicio} = separarFechaHora(convertirLocalAUTC(combinarFechaHoraUser(serieConfig.startDate,serieConfig.startTime,zonaHoraria),zonaHoraria));
  const {fecha:fecha_fin,hora:hora_fin} = separarFechaHora(convertirLocalAUTC(combinarFechaHoraUser(serieConfig.endDate,serieConfig.endTime,zonaHoraria),zonaHoraria));
  console.log("inicio serie original:",combinarFechaHoraUser(serieConfig.startDate,serieConfig.startTime,zonaHoraria));
  console.log("fin Serieoriginal:",combinarFechaHoraUser(serieConfig.endDate,serieConfig.endTime,zonaHoraria));
  console.log("inicio serie:",fecha_inicio, hora_inicio);
  console.log("fin Serie:",fecha_fin, hora_fin);*/
  const fechaCombinadaInicio=convertirAUTC(serieConfig.startDate,serieConfig.startTime,timefitUser.zona_horaria);
  const fechaCombinadaFin=convertirAUTC(serieConfig.endDate,serieConfig.endTime,timefitUser.zona_horaria);
  console.log("fechas Serie:",fechaCombinadaInicio,fechaCombinadaFin);
  const {fecha:fecha_inicio, hora:hora_inicio} = separarFechaHoraISO(fechaCombinadaInicio); 
  const {fecha:fecha_fin,hora:hora_fin} = separarFechaHoraISO(fechaCombinadaFin);


  
  return {
      start_date:fecha_inicio,// serieConfig.startDate,
      end_date: fecha_fin,//serieConfig.endDate,
      start_time: hora_inicio,//serieConfig.startTime,
      end_time: hora_fin,//serieConfig.endTime,
      repeat_count: serieConfig.repeatCount,
      repeat_type: serieConfig.repeatType,
      interval: serieConfig.interval || 1,
      days_of_week: serieConfig.daysOfWeek.join(','), // Convertir array a string
      // Añade más campos o transformaciones si es necesario
  };
}




function transformEventoToDBFormat(Evento) {
  const { serie, ...eventoSinSerie } = Evento;



  console.log("el evento con serie recien elimando a agregar es:",eventoSinSerie)
  
  return eventoSinSerie;
      // Añade más campos o transformaciones si es necesario
}

const ColorPicker = ({ selectedColor, onColorSelect, colores }) => {
    const handleColorClick = (e, color) => {
      e.stopPropagation(); // Detiene la propagación del evento
      onColorSelect(color);
    };
  
    return (
      <div className="color-picker">
        {colores.map(color => (
          <button 
            key={color}
            type="button" // Especifica que este botón no es de tipo submit
            style={{ 
              backgroundColor: color, 
              width: '30px', 
              height: '30px', 
              borderRadius: '50%', 
              margin: '5px',
              border: selectedColor === color ? '2px solid black' : 'none'
            }} 
            onClick={(e) => handleColorClick(e, color)}
            onTouchStart={(e) => handleColorClick(e, color)}
          />
        ))}
      </div>
    );
  };
  
const coloresGoogleCalendar = [
    '#039BE5', // Azul
    '#33B679', // Verde
    '#8E24AA', // Morado
    '#E67C73', // Rojo
    '#F6BF26', // Amarillo
    '#F4511E', // Naranja
    '#7986CB', // Lavanda
    '#616161', // Grafito
    '#0B8043', // Verde bosque
    '#D50000', // Rojo oscuro
    '#F06292', // Rosa
    '#3F51B5', // Índigo
  ];
  

const EventForm = ({ onSave, onCancel, onDelete, onUpdate, initialEvent,var_usuario }) => {
  // Asegúrate de que el formato sea adecuado para input datetime-local
  const formatDateTimeLocal = (date) => {
    //fechaUTC=convertirLocalAUTC(date,zonaHoraria);
    return moment(date).format('YYYY-MM-DDTHH:mm');
  };
  const { t } = useTranslation();



  // Estado inicial para los inputs
  const [title, setTitle] = useState(initialEvent.title || '');
  const [start, setStart] = useState(formatDateTimeLocal(initialEvent.start || new Date()));
  const [end, setEnd] = useState(formatDateTimeLocal(initialEvent.end || new Date()));
  const [allDay, setAllDay] = useState(initialEvent.allDay || false);
  const [isCompleted, setIsCompleted] = useState(initialEvent.isCompleted || false);
  const [color, setColor] = useState(initialEvent.color || '#3174ad'); // color por defecto
  const [description, setDescription] = useState(initialEvent.description || '');
  const [repeat, setRepeat] = useState(initialEvent.repeat || 'none'); // Ejemplo: 'none', 'daily', 'weekly', etc.
  const [showEditor, setShowEditor] = useState(false);
  // Estado para controlar si el `EventForm` está deshabilitado
  const [isEventFormDisabled, setIsEventFormDisabled] = useState(false);
  //const cleanHTML = DOMPurify.sanitize(description);
  const [cleanHTML, setCleanHTML] =useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showSerieConfig, setShowSerieConfig] = useState(initialEvent.serie && initialEvent.serie.daysOfWeek ?true:false);
  const [serieConfig, setSerieConfig] = useState(initialEvent.serie?initialEvent.serie:{
    daysOfWeek: []
  });
      //daysOfWeek: [],
        // Define aquí la estructura inicial de tu serieConfig

  const [isMobile, setIsMobile] = useState(false);
  const { timefitUser } = useUserContext();
  /*const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {setIsModalOpen(true); setShowEventForm(false);} // Función para abrir el modal
  const closeModal = () => {setIsModalOpen(false);setShowEventForm(true);} // Función para cerrar el modal*/


  const handleOpenEditor = () => {
    setIsEventFormDisabled(true); 
    setShowEditor(true);
    // Desactivar el `EventForm`
  };

  const handleCloseEditor = () => {
    setShowEditor(false);
    setIsEventFormDisabled(false); // Reactivar el `EventForm`
  };

  const saveContent = (content) => {
    console.log('Contenido guardado:', content);
    // Aquí puedes manejar el contenido guardado (por ejemplo, actualizar el estado o enviar a un backend)
  };
  const [showEventForm, setShowEventForm] = useState(true);
  //const [value, setValue] = useState('');
  // Actualiza el estado si el evento inicial cambia
  useEffect(() => {
    setTitle(initialEvent.title || '');
    setStart(formatDateTimeLocal(initialEvent.start || new Date()));
    setStartDate(initialEvent.start || new Date());
    setEndDate(initialEvent.end || new Date());
    setEnd(formatDateTimeLocal(initialEvent.end || new Date()));
    setAllDay(initialEvent.allDay || false);
    setColor(initialEvent.color || '#3174ad');
  }, [initialEvent]);


  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

    // Prevent event propagation on form elements
    const handleClick = (event) => {
      event.stopPropagation(); // Prevents event from propagating to the calendar or other elements
    };


  useEffect(() => {
    const htmlContent = convertToHTML(description);
    //const htmlContent = convertBlocksToHtml(description);

    setCleanHTML(htmlContent);
    console.log("Description, Html,clenHtml:",description,htmlContent,cleanHTML);

  },[description])
  //const cleanHTML = convertToHTML(description); 
    // Crear la referencia
    const modalRef = useRef(null);

    // Manejador para cerrar el formulario si se hace clic fuera
    const handleOutsideClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)&&!showEditor) {
        //e.stopPropagation();
        onCancel(); // Cierra el formulario
      }
    };
  
    // Efecto para agregar el manejador de eventos
    useEffect(() => {
      if (!showEditor )
      {      
        document.addEventListener('mousedown', handleOutsideClick);
      }
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }, [showEditor]);

    useEffect(() => {
      // Detect if the user is on a mobile device based on screen size
      const handleResize = () => setIsMobile(window.innerWidth <= 768);
      window.addEventListener('resize', handleResize);
      handleResize(); // Initialize on component mount
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    const handleSubmit = async (event) => {
      event.stopPropagation();
      event.preventDefault();
        const newEvent = {
          titulo: title, // Valor obtenido del formulario
          descripcion: description, // Valor obtenido del formulario
          inicio:convertirLocalAUTC(startDate,timefitUser.zona_horaria),// start, // Valor obtenido del formulario
          fin: convertirLocalAUTC(endDate,timefitUser.zona_horaria),//end, // Valor obtenido del formulario
          todo_el_dia: allDay, // Valor obtenido del formulario, por ejemplo, booleano
          repetir: repeat, // Valor obtenido del formulario, por ejemplo, 'diario', 'semanal'
          serie_id: null, // Valor generado o obtenido, por ejemplo, UUID si es parte de una serie repetida
          color: color, // Valor obtenido del formulario, por ejemplo, código de color
          estado: '', // Valor obtenido del formulario, por ejemplo, 'pendiente', 'completado'
          user_id: var_usuario, //usario del form
          serie:event.serie?event.serie:null, //datos de la serie
          is_completed: isCompleted,
        };
        
        let savedEvent;
        savedEvent = {
            //...initialEvent,
           id:initialEvent.id,
           title,
           start: convertirLocalAUTC(startDate,timefitUser.zona_horaria),// new Date(start),
           end: convertirLocalAUTC(endDate,timefitUser.zona_horaria),//new Date(end),
           allDay,
           description,
           repeat,
          color,
          serieConfig,
          isNew: !initialEvent.id,
          serie: {},
          isCompleted,
         }
        const eventDB=transformEventoToDBFormat(newEvent);
        if (initialEvent.id && !newEvent.serie) {
          eventDB.id=initialEvent.id;
          onUpdate(eventDB)
        
        } 
        else if (initialEvent.id && newEvent.serie)
        {
          onUpdate(newEvent)
        }

    
        
        
        else {
          console.log('Guardando el evento');
            try {
              if (!showSerieConfig) {
                const eventodb = await addEvent(eventDB);
                console.log("evento recien creado en la BD",eventodb)
                savedEvent.id=eventodb.id;
                // Suponiendo que eventodb.id contiene el ID que quieres asignar


                console.log("evento savedEvent luego de asignarle el id",savedEvent)
              }
              // Lógica adicional después de agregar el evento
              else 
              {
                const serieData=transformSerieConfigToDBFormat(serieConfig,timefitUser);
                console.log("el evento con serie a agregar es:",eventDB)
                const {eventodb1,seriedb1} = await insertEventWithSeries(eventDB, serieData);
                console.log('Este es el evento recién guardado en la BBDD:',eventodb1)
                console.log('Esta es la serie recién guardada en la BBDD:',seriedb1)
                savedEvent.id=eventodb1.id;
                savedEvent.serie=seriedb1;

              }
            } catch (error) {
              console.error('Error al guardar el nuevo evento:', error);
              // Manejo de errores
        }
      }
      console.log("evento antes del Onsave",savedEvent)
      onSave(savedEvent);
      };
    //  console.log('Valor de start:', start);
    //  console.log('Tipo de start:', typeof start);
  // Asegúrate de que este manejador no se active con clics dentro del formulario
  // Puede que necesites usar un ref y agregar un manejador de eventos adecuado.
  //            <ReactQuill value={description} onChange={handleDescriptionChange} />
  const CustomInput = ({ value, onClick, onChange }) => {
    const readOnly = isMobile;
    return (
    <input
      style={customStyles.input}
      value={value}
      onClick={onClick}
      onChange={onChange} // Ensure onChange is handled if needed
      readOnly={readOnly} // Prevent keyboard from opening
    />)
    };



  return (
    <div>
    {/* Mostrar el editor modal */}
    <Draggable
    disabled={isMobile} // Disable draggable on mobile devices
    cancel="input, .formatted-editor-content" // Elements where dragging is disabled
    >
    <TextEditorModal
      isOpen={showEditor}
      onClose={handleCloseEditor}
      onSave={(newContent) => setDescription(newContent)}
      initialContent={initialEvent.description || ''}
    />
    </Draggable>
    <div className="modal-overlay" ref={modalRef}>
      {/* Si el `EventForm` está deshabilitado, aplicar una clase para reducir la opacidad */}
      <div className={`event-form-wrapper ${isEventFormDisabled ? 'disabled' : ''}`}></div>
        <Draggable
        disabled={isMobile} // Disable draggable on mobile devices
        cancel="input, .formatted-editor-content" // Elements where dragging is disabled
        >
        <div className="modal-content">
            <form onSubmit={handleSubmit}>
            <label>
            {t('name')}
           <input type="text" value={title} onChange={e => setTitle(e.target.value)} />
          </label>
          <br />
          <div className="date-picker-container">
          {t('start')}:
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="time"
            dateFormat="yyyy-MM-dd HH:mm"
            customInput={<CustomInput />}
          /></div>
          <br/>
          <div className="date-picker-container">
          {t('end')}:
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="time"
            dateFormat="yyyy-MM-dd HH:mm"
            customInput={<CustomInput />}
          /></div>
          {/*}
          <label>
          {t('start')}:
           <input type="datetime-local" value={start} name="EventStartDate" required onChange={e => setStart(e.target.value)} />
  </label>
           <br />
           <label>
           {t('end')}:
             <input type="datetime-local" value={end} name="EventEndDate" required min={initialEvent.start} onChange={e => setEnd(e.target.value)} />
           </label>*/}
           <br />
           <label>
           {t('notes')}:
            {/*<textarea value={description} onChange={e => setDescription(e.target.value)} />*/}
            {/*!showEditor && (
            <div onClick={() => handleOpenEditor()} onTouchStart={() => setShowEditor(true)}  dangerouslySetInnerHTML={{ __html: cleanHTML }}
            style={{ cursor: 'pointer', border: '1px solid #ccc', padding: '7px' }}></div>
            )*/}
                {!showEditor && (
      <div
        onClick={handleOpenEditor}
        onTouchStart={() => setShowEditor(true)}
        dangerouslySetInnerHTML={{ __html: cleanHTML }}
        className="formatted-editor-content" // Clase con los estilos del editor        
      ></div>
    )}

            {/*<MarkdownEditor value={value} onChange={(editor, data, value) => setValue(value)}/>*/}
          </label>
          <label>
          Completado:
          <input
                type="checkbox"
                checked={isCompleted}
                onChange={(e) => setIsCompleted(e.target.checked)}
                />
            </label>
          {/*<button type="button" onClick={() => setShowEditor(true)}>
          Editar Descripción
        </button>
          <br />
          <label>
            Repetir:
            <select value={repeat} onChange={e => setRepeat(e.target.value)}>
              <option value="none">No repetir</option>
              <option value="daily">Diariamente</option>
              <option value="weekly">Semanalmente</option>
            </select>
          </label>*/}
          {!initialEvent.id && (
          <label>
            {t('recurringEvent')}
            <input
                type="checkbox"
                checked={showSerieConfig}
                onChange={(e) => setShowSerieConfig(e.target.checked)}
            />
        </label>)
        }

        {showSerieConfig && (
            <SerieConfig
                serieConfig={serieConfig}
                setSerieConfig={setSerieConfig}
                serieStartDate={format(new Date(start), 'yyyy-MM-dd')}
                serieStartTime={format(new Date(start), 'HH:mm')}
                serieEndTime={format(new Date(end), 'HH:mm')}
            />
        )}
          <br />
           <label>
           {t('allDay')}
            <input type="checkbox" checked={allDay} onChange={e => setAllDay(e.target.checked)} />
          </label>
            <br />
            <label>
            {t('color')}
            <ColorPicker selectedColor={color} onColorSelect={setColor} colores={coloresGoogleCalendar} />
            </label>
         <br />
         {
            !(initialEvent.id && showSerieConfig) &&(
             <StyledButton 
              type="submit"
              disabled = {showEditor}
              >
                {initialEvent.id ? t('update') : t('save')}
             </StyledButton>
            )
           
          }

            {initialEvent.id && (
            <StyledButton type="button"  disabled = {showEditor} onClick={() => onDelete(initialEvent) } >{t('delete')}</StyledButton>
          )}
            <StyledButton type="button"  disabled = {showEditor} onClick={onCancel} >{t('cancel')}</StyledButton>
        </form>

      </div>
      </Draggable>
    </div>
    </div>
  );
};

export default EventForm;
