import styled from 'styled-components';

// Creando un componente de encabezado estilizado
const StyledHeader = styled.header`
  background-color: #282c34;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
`;

const Header = ({ title }) => {
  return <StyledHeader>{title}</StyledHeader>;
};

export default Header;
