import React from 'react';
import { useTranslation } from 'react-i18next';
import './i18n'; // Asume que tu archivo de configuración se llama i18n.js
import LanguageButton from './LanguageButton'; 


function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  const currentLanguage = i18n.language;

  return (
    <div>
       <LanguageButton
        onClick={() => changeLanguage('en')}
        active={currentLanguage.startsWith('en')}
      >
        English
      </LanguageButton>
      <LanguageButton
        onClick={() => changeLanguage('es')}
        active={currentLanguage.startsWith('es')}
      >
        Español
      </LanguageButton>
      {/* Agrega botones adicionales para otros idiomas como 'fr', 'pt', etc. */}
    </div>
  );
}

export default LanguageSwitcher;

/*
 active={currentLanguage.startsWith('en')}
active={currentLanguage.startsWith('es')}
*/