// UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { manageUser } from '../api/supabaseClient';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { isAuthenticated, user } = useAuth0(); // Auth0 data
  const [timefitUser, setTimefitUser] = useState(null);

  useEffect(() => {
    if (isAuthenticated && user) {
      const loadUser = async () => {
        try {
          const userData = await manageUser(user); // Your manageUser function
          setTimefitUser(userData);
        } catch (error) {
          console.error('Error loading user data:', error);
        }
      };

      loadUser(); // Load Timefit user data after authentication
    }
  }, [isAuthenticated, user]);

  return (
    <UserContext.Provider value={{ timefitUser, setTimefitUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
