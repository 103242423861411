// src/components/VideoPage.js
import React from 'react';

const VideoPage = () => {
  return (
    <div>
      <h1>Video TimeFit</h1>
      <iframe
        width="560" 
        height="315" 
        src="https://www.youtube.com/embed/k3CRVwJEfQ4" 
        title="YouTube video player" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowfullscreen>
      </iframe>
    </div>
  );
};

export default VideoPage;
