import styled from 'styled-components';


export const IconContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  margin-bottom: 20px; // Espacio antes del contenido principal
`;

export const IconButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px; // Tamaño del icono
  height: 40px; // Tamaño del icono
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0;
  font-size: 24px; // Tamaño del ícono dentro del botón
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #0056b3;
  }

  &:focus {
    outline: none;
  }
`;
