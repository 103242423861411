import React, { useEffect, useState } from 'react';
import { getTasks } from '../api/tasksApi'; // Asegúrate de tener la ruta correcta a tu API
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {TagSelectorComponent} from './TagSelector';


const ColumnsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 20px;
  gap: 20px; /* Espacio entre columnas */
`;

const TaskColumn = styled.div`
  flex: 1; /* Distribuye el espacio igualmente entre columnas */
  background-color: #f5f5f5; /* Fondo ligero para las columnas */
  border-radius: 8px; /* Bordes redondeados para suavizar el diseño */
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra suave para profundidad */
`;

const TaskItem = styled.div`
  background-color: #fff; /* Fondo blanco para los elementos de tarea */
  color: #333; /* Color de texto */
  margin-bottom: 10px; /* Espacio entre tareas */
  padding: 10px;
  border-radius: 4px; /* Bordes redondeados para los elementos de tarea */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Sombra suave */
  cursor: pointer; /* Indica que el elemento es clickeable */
  &:hover {
    background-color: #e9e9e9; /* Cambia el fondo al pasar el mouse */
  }
`;



const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Group = styled.div`
  margin: 10px 0;
`;
/*
const TaskItem = styled.div`
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #f0f0f0;
  }
`;*/

const GroupTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 5px;
`;


const TaskList = ({ userid, onSelectTask }) => {
    const [tasks, setTasks] = useState([]);
    const [filter, setFilter] = useState({ tag: '', status: '', date: '' });
    const [selectedTags, setSelectedTags] = useState([]);
    const { t } = useTranslation();

    const filteredTasks = tasks.filter(task => {
      return selectedTags.length === 0 || task.tasktags.some(tag => selectedTags.includes(tag.tag_id));
    });

    const tareasAgrupadasPorEstado = filteredTasks.reduce((acc, task) => {
      if (!acc[task.estado]) acc[task.estado] = [];
      acc[task.estado].push(task);
      return acc;
    }, {});
  
    useEffect(() => {
      const fetchTasks = async () => {
        const fetchedTasks = await getTasks(userid);
        setTasks(fetchedTasks);
      };
      fetchTasks();
      console.log("Tareas de la lista",tasks);
    }, [userid]);
  
    const handleSelectTask = (task,tasks) => {
      onSelectTask(task,tasks); // Función para abrir la tarea en TaskDriver
    };
  
 

    const estados = ["pendiente", "en curso", "completado"];


  
return (
  <div>

  <TagSelectorComponent
  selectedTags={selectedTags}
  setSelectedTags={setSelectedTags}
/>

  <ColumnsContainer>

  {estados.map((estado) => (
    <TaskColumn key={estado}>
      <h3>{t(estado)}</h3> {/* Capitaliza el título del estado */}
      {(tareasAgrupadasPorEstado[estado] || []).map((task) => (
        <TaskItem key={task.id} onClick={() => handleSelectTask(task,tasks)}>
          {task.titulo}
        </TaskItem>
      ))}
      {(!tareasAgrupadasPorEstado[estado] || tareasAgrupadasPorEstado[estado].length === 0) && (
        <p>{t("No hay tareas")}</p> // Mensaje cuando no hay tareas en el estado
      )}
    </TaskColumn>
  ))}
</ColumnsContainer>
</div>
);

  };
export default TaskList;
