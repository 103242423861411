import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {getTasks} from '../api/tasksApi';
import TaskList from './TaskList';
import { useTranslation } from 'react-i18next';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
`;

const ModalHeader = styled.h2`
  margin-top: 0;
`;
/*
const TaskList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;*/

const TaskItem = styled.li`
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const CloseButton = styled.span`
  float: right;
  cursor: pointer;
`;

// Props adicionales pueden ser pasados según sea necesario
const TaskSelectorModal = ({ isOpen, onClose, userId, onSelectTask}) => {
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      getTasks(userId)
        .then(fetchedTasks => {
          setTasks(fetchedTasks);
          console.log("fetechedTasks: ",fetchedTasks);
        })
        .catch(error => {
          console.error("Error fetching tasks:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isOpen, userId]);

  const handleSelectTask = (task,tasks) => {
    onSelectTask(task,tasks);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContent>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <ModalHeader>{t("SelectTask")}</ModalHeader>
        {isLoading ? (
          <p>{t("loading")}</p>
        ) : (
            <TaskList userid={userId} onSelectTask={handleSelectTask} />
        )}
      </ModalContent>
    </ModalOverlay>
  );
};

export default TaskSelectorModal;
