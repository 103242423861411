import React, { useState, useCallback ,useRef,useEffect} from 'react';
import EditableElement from './EditableElement';
import { FiLayers,FiEdit,FiPlus,FiFileText, FiImage, FiBookmark, FiSave,FiPrinter, FiFile } from 'react-icons/fi';
import { IconButton } from './styles';
import styled from 'styled-components';
import {deleteImage,uploadImageToBucket,saveCurrentTaskElements, getTaskElements,deleteTask,saveTaskWithTags,updateTaskWithTags,getTaskWithTags} from '../api/tasksApi';
import {generateMiniMapImage,SavePDF} from './generatePDF';
import TaskForm from './TaskForm';
import Modal from '../design/Modal'; // Asegúrate de que la ruta sea correcta
import { addTask,updateTask } from '../api/tasksApi'; 
import { useTranslation } from 'react-i18next';
import TaskSelectorModal from './TaskSelectorModal';
import MiniMap from './MiniMap'; 
//import { use } from 'bcrypt/promises';

const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
  gap: 5px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Calcula el ancho y alto en base al nivel de zoom */
  width: ${({ zoomlevel }) => 794 * zoomlevel}px; /* Ancho inicial de A4 ajustado por zoomLevel */
  height: ${({ zoomlevel }) => 1123 * zoomlevel}px; /* Alto inicial de A4 ajustado por zoomLevel */
  overflow-y: auto;
  margin: auto;
  position: relative; /* Si necesitas posicionar elementos absolutamente dentro */
`;
// Creación de la referencia al contenedor

const IconContainer = styled.div`
  position: fixed;
  top: 10px; /* Ajuste inicial, puedes cambiarlo */
  left: 10px; /* Ajuste inicial, puedes cambiarlo */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* Ajuste para el espacio entre botones */
  z-index: 11; /* Asegura que el contenedor esté por encima de otros elementos */
  background: white; /* Fondo para mejorar la visibilidad */
  padding: 10px; /* Espacio alrededor del contenido */
  border-radius: 8px; /* Bordes redondeados para estética */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para resaltar el contenedor */
  cursor: move; // Cambia el cursor a "mover" para indicar que el contenedor es arrastrable
`;




const Panel = ({selectedTask_Id,task_i,user,actualizarTareas}) => {

  //const panelRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({x: 10, y: 10}); // Posición inicial
  const [zoomLevel, setZoomLevel] = useState(1); // 1 es el nivel de zoom inicial
  //const [contentPosition, setContentPosition] = useState({ x: 0, y: 0 });
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 }); // Posición de desplazamiento inicial.
  const [canvasSize, setCanvasSize] = useState({ width: 794, height: 1123 });
  const [backGrounMiniMap,setbackGrounMiniMap] = useState(null);
  const panelContainerRef = useRef(null);
  const iconContainerRef = useRef(null);
  const fileInputRef = useRef(null);
  const elementRefs = useRef({});
  const [selectedTaskId, setTaskID] = useState(selectedTask_Id);
  //console.log("Initial task_i:", task_i);
  const [task,setTask] = useState(task_i);
  const [elements, setElements] = useState([]);
  const [isLoadingElements, setIsLoadingElements] = useState(false);
  const [deletedUrls, setDeletedUrls] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ModalEditing, setModalEditing] = useState(false);
  const [UrlTemp,setUrlTemp] =useState(null);
  const handleCloseModal = () => setIsModalOpen(false);
  const { t } = useTranslation();
  // Funciones para manejar el zoom
  const zoomIn = () => setZoomLevel(zoomLevel * 1.1);
  const zoomOut = () => setZoomLevel(zoomLevel / 1.1);

  const [isTaskSelectorModalOpen, setIsTaskSelectorModalOpen] = useState(false);

  const handleOpenTaskSelectorModal = () => {
      setIsTaskSelectorModalOpen(true);
  };

  const handleCloseTaskSelectorModal = () => {
      setIsTaskSelectorModalOpen(false);
  };

  const handleSelectTask = (task1) => {
      // Lógica para manejar la tarea seleccionada
      console.log("Tarea seleccionada:", task);
      setTaskID(task1.id); // Cerrar el modal después de seleccionar la tarea
      setTask(task1);
      actualizarTareas(task1);

  };
  useEffect(() => {
    setCanvasSize({
        width: 794 * zoomLevel,
        height: 1123 * zoomLevel
    });
}, [zoomLevel]);


  function ocultarFondo() {
    document.body.style.background = "none"; // Esto quita el fondo del cuerpo del documento
    // Aquí puedes añadir cualquier otro elemento que desees ocultar o modificar
  }
  
  // Función para restaurar el fondo
  function restaurarFondo() {
    document.body.style.background = ""; // Restaura el fondo previo o puedes especificar uno nuevo
  }

  useEffect(() => {
    setTask(task_i);

  }, [task_i]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleFondoMiniMap();
    }, 800); // Un delay de 0 ms asegura que se coloque al final de la cola del event loop
  
    return () => clearTimeout(timer);
    

  },[elements]);

  const startDragging = (e) => {
    setIsDragging(true);
    e.preventDefault(); // Evita el comportamiento por defecto
  };
  
  // Mover icono
  const onMove = (e) => {
    if (isDragging) {
      setPosition({ x: e.clientX, y: e.clientY });
    }
  };
  
  // Detener arrastre
  const stopDragging = () => {
    setIsDragging(false);
  };
  
useEffect(() => {
  if(UrlTemp)
  {
    addElement('image', UrlTemp);
  }
  handleFondoMiniMap();

},[UrlTemp])

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', onMove);
      document.addEventListener('mouseup', stopDragging);
      return () => {
        document.removeEventListener('mousemove', onMove);
        document.removeEventListener('mouseup', stopDragging);
      };
    }
  }, [isDragging, onMove]);

  useEffect(() => {
    //setTaskID(task.id);
    
    if (!(task?.id===undefined) && task ){
      console.log("entrado al useeffect del Panel con: task:",task,task?.id)
      setIsLoadingElements(true); 
      setElements([]);
      // Cargar elementos de la tarea seleccionada
      
      getTaskElements(task.id)
        .then((loadedElements) => {
          setElements(loadedElements);
          console.log("panel elements",loadedElements );
          setIsLoadingElements(false);
        })
        .catch((error) => {
          console.error("Error al cargar elementos de la tarea:", error);
          setIsLoadingElements(false);
          // Maneja el error adecuadamente (mostrar mensaje de error, etc.)
        });
    } else {
      // Si no hay tarea seleccionada o se selecciona crear una nueva, limpia los elementos actuales
      setElements([]);
    }
    //handleFondoMiniMap();
    
  }, [task]);



const getPanelContainerPosition = () => {
  if (panelContainerRef.current) {
    const rect = panelContainerRef.current.getBoundingClientRect();
    return {
      left: rect.left,
      top: rect.top,
      width: rect.width,
      height: rect.height
    };
  }
  return { left: 0, top: 0, width: 0, height: 0 };
};


  const handleWheel = (e) => {
    if (e.ctrlKey) { // Verifica si la tecla Ctrl está presionada
      // Calcula la posición del mouse relativa al PanelContainer
      const { left, top } = getPanelContainerPosition();
      const mouseX = e.clientX;
      const mouseY = e.clientY;
      const oldZoomLevel = zoomLevel;
      const newZoomLevel = e.deltaY > 0 ? zoomLevel -0.5 : zoomLevel +0.5;

      const rect = panelContainerRef.current.getBoundingClientRect();
      const RectTop=rect.top;
      const RectLeft=rect.left;
      const containerX = window.scrollX + mouseX; // Ajusta por el desplazamiento del documento
      const containerY = window.scrollY + mouseY; // Ajusta por el desplazamiento del documento
      //console.log("rect: ", rect)
      //console.log("rectTop,rectLeft,zoomLevel: ", RectTop,RectLeft,zoomLevel);
      //console.log("museX,museY: ", mouseX,mouseY);
      //console.log("window.scrollX,window.scrollY: ", window.scrollX,window.scrollY);
      const relativeMouseX = mouseX - RectLeft;
      const relativeMouseY = mouseY - RectTop;
  
      // Asegura que el zoomLevel esté dentro de límites razonables
      if (newZoomLevel < 0.5 || newZoomLevel > 100) return;
  
      // Calcula la diferencia de zoom
      //console.log("zoom new, old: ",newZoomLevel,oldZoomLevel);
      const zoomRatio = newZoomLevel / (oldZoomLevel>=1.5? oldZoomLevel:newZoomLevel);
      //console.log("zoom Ratio, new, old: ",zoomRatio,newZoomLevel,oldZoomLevel);
  
      // Calcula la nueva posición del contenido
      const newContentPositionP = {
        x: containerX * zoomRatio-mouseX,
        y: containerY * zoomRatio-mouseY
      };

      const newScrollLeft = mouseX * newZoomLevel - window.innerWidth / 2;
      const newScrollTop = mouseY * newZoomLevel - window.innerHeight / 2;

      const newContentPosition = {
        x: relativeMouseX,
        y: relativeMouseY
      };

      //e.preventDefault(); // Previene el comportamiento predeterminado del navegador
      const delta = e.deltaY > 0 ? -0.5 : 0.5; // Determina la dirección del zoom

      setZoomLevel(prevZoom => Math.min(Math.max(prevZoom + delta, 0.5), 100)); // Ajusta el nivel de zoom dentro de límites razonables
      console.log("pre zoom al Panel: ", newContentPositionP)
      setScrollPosition(newContentPositionP);
    }
   /* else
    {
      
     //const posicion=getPanelContainerPosition();
     const newContentPositionP = {
      x: window.scrollX ,
      y: window.scrollY 
    };
      setScrollPosition(newContentPositionP);
    }*/
  };




  // Actualiza la posición y el tamaño de un elemento
  const onUpdateElement = useCallback((id, newPosition, newSize,newContent) => {
    setElements(currentElements =>
      currentElements.map(element => 
        element.id === id ? { ...element, position: newPosition, size: newSize, content: newContent } : element
      )
    );
    handleFondoMiniMap();
  }, []);

  // Elimina un elemento del panel
  const handleDeleteElement = useCallback((id) => {
    setElements((currentElements) => {
      const elementToDelete = currentElements.find((element) => element.id === id);
      // Comprobar que el elemento es de tipo 'image' antes de agregar su URL al arreglo de eliminados
      if (elementToDelete && elementToDelete.type === 'image') {
        setDeletedUrls((currentDeletedUrls) => [...currentDeletedUrls, elementToDelete.content]);
      }
      return currentElements.filter((element) => element.id !== id);
    });
  }, []);

  const handleDelete = async () => {
    // Muestra una alerta de confirmación al usuario
    const isConfirmed = window.confirm(`¿Estás seguro de eliminar la tarea "${task.titulo}"?`);

    if (isConfirmed) {
      try {
        await deleteTask(Number(task.id));
        alert(`La tarea "${task.titulo}" ha sido eliminada.`);
        // Si tienes una función de callback para actualizar la lista de tareas en el componente padre, llámala aquí
        actualizarTareas(task,task.id);
        //onTaskDeleted(task.id);
      } catch (error) {
        console.error('Error al eliminar la tarea:', error);
        // Manejar el error (opcionalmente puedes mostrar un mensaje de error al usuario)
      }
      handleCloseModal();
    }
  };




  


  const handleAddImage = () => {
    // Opción para ingresar URL
   // const imageUrl = window.prompt("Ingrese la URL de la imagen:");
    fileInputRef.current.click(); // Simula un clic en el input de tipo file
    //if (imageUrl) {
      // Agrega la imagen con la URL proporcionada
      //addElement('image', imageUrl);
      //return;
    //}

    // O si prefieres subir desde el dispositivo, puedes usar un input file (esto es más complejo y requiere un manejo de archivos)
    // Aquí puedes iniciar el proceso de selección de archivo y luego manejar la carga
  };

  // Asume que esta función carga la imagen y retorna la URL pública
const uploadImageToBucketv1 = async (file) => {
  try {
    const { data, publicURL, error } = await uploadImageToBucket(file);

      if (error) {
          throw new Error('Error al subir la imagen');
      }

      // Asume que la respuesta incluye la URL directamente o en un objeto
      const imageUrl = publicURL.publicUrl; // Adapta según la estructura real de tu respuesta
      return imageUrl;
  } catch (error) {
      console.error(error.message);
      throw error; // Propaga el error para manejarlo en el llamador
  }
};

/*
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const { data, publicURL, error } = await uploadImageToBucket(file);

    if (error) {
        // Manejar el error adecuadamente
        alert('Error al subir la imagen');
        return;
    }
    console.log("se ha subido la imagen correctamente en: ",publicURL.publicUrl);
    // Aquí puedes proceder a actualizar el estado o realizar otras acciones con la URL pública de la imagen
    addElement('image', publicURL.publicUrl);
};
*/

const handleImageUpload = async (event) => {
  const file = event.target.files[0];
  if (!file) return;

  try {
      const imageUrl = await uploadImageToBucketv1(file);
      setUrlTemp(imageUrl);
      //addElement('image', imageUrl);
      console.log("Se ha subido la imagen correctamente en:", imageUrl);

      // Procede a actualizar el estado o realizar otras acciones con la URL pública de la imagen
      
  } catch (error) {
      alert(error.message); // Asume que el manejo de errores en `uploadImageToBucket` ya registra el error
  }
};




  // Añade un nuevo elemento al panel
  const addElement = useCallback((type,content = 'T') => {

    const container = panelContainerRef.current;
    if (!container) return;
    const centerX = window.scrollX + window.innerWidth / 3;
    const centerY = window.scrollY + window.innerHeight / 3;
  
    // Transforma las coordenadas del centro a coordenadas relativas al PanelContainer
    // Esto depende de cómo esté posicionado tu PanelContainer y podría requerir ajustes
    //const panelRect = panelContainerRef.current.getBoundingClientRect();
    const positionX = centerX;// - panelRect.left;
    const positionY = centerY; // - panelRect.top;
  
  
    // Usa centerX y centerY como la posición para el nuevo elemento
    const newPosition = { x: positionX/zoomLevel, y: positionY/zoomLevel };
    const baseWidth = 200/zoomLevel; // Ancho base sin zoom
    const baseHeight = 100/zoomLevel; // Alto base sin zoom
    //const newPosition = { x: 100 / zoomLevel, y: 100 / zoomLevel }; // Posición sin ajustar por el zoom
    const fontSizeBase = baseHeight * 0.2; // Ejemplo: el 20% de la altura de la caja

    const newElement = {
      id: `element-${Date.now()}`, // Usar timestamp para ID único
      type:type,
      position: newPosition,
      size: { width: baseWidth, height: baseHeight},
      fontSize: fontSizeBase, // Almacena el tamaño de fuente calculado
      content: content,
      color: type === 'postit' ? 'yellow' : 'transparent',
      zoomlevel:{zoomLevel}
    };
    setElements(prevElements => [...prevElements, newElement]);
    // Inicializa el ref para el nuevo elemento
    elementRefs.current[newElement.id] = React.createRef();
  }, [zoomLevel]);
  
  
  const handleSavePDF = async () => {
    // Encuentra el elemento IconContainer por su clase o id
    const iconContainer = document.querySelector('.icon-container-class');
    // Añade la clase print-hide para ocultarlo
    iconContainer.classList.add('print-hide');
    ocultarFondo();
  
    await SavePDF(elements,zoomLevel); // Genera el PDF
  
    // Remueve la clase print-hide para que el IconContainer vuelva a ser visible
    iconContainer.classList.remove('print-hide');
    restaurarFondo();
  };

  const handleFondoMiniMap= async () => {
    // Encuentra el elemento IconContainer por su clase o id
    const iconContainer = document.querySelector('.icon-container-class');
    // Añade la clase print-hide para ocultarlo
    iconContainer.classList.add('print-hide');
    ocultarFondo();
    const loadMiniMapImage = async () => {
      const image = await generateMiniMapImage(panelContainerRef);
      setbackGrounMiniMap(image);
      console.log("Se generó un uevo MiniMap Background");
    };

    loadMiniMapImage();
  
    //setbackGrounMiniMap(generateMiniMapImage(elements)); 
  
    // Remueve la clase print-hide para que el IconContainer vuelva a ser visible
    iconContainer.classList.remove('print-hide');
    restaurarFondo();
  };
  
const handleSaveTask = async() => {

  setTaskID(task.id);
  console.log("task en Panel,taskId:",task,task.id)
  console.log("deletesURLS",deletedUrls)
  await saveCurrentTaskElements(task.id,elements);
   // Eliminar imágenes del bucket de Supabase
   if (deletedUrls)
   {
   for (const url of deletedUrls) {
    if (url !== null) {
      const filePath = url.split('/').slice(-2).join('/'); // Ajusta según tu estructura de URL
      await deleteImage(filePath); 
    }
   }
   setDeletedUrls([]);
  }
  

  }
  const handleViewChange = async() => {


  }

  const handleSaveTaskId = async (taskData) => {
    console.log(taskData); // Aquí integrarías la lógica para guardar la tarea
    setIsModalOpen(false); // Cerrar el modal después de guardar
        try {
            const newTask = await saveTaskWithTags(taskData);
            actualizarTareas(newTask);
            console.log(newTask);
            // Actualizar la UI aquí o redireccionar al usuario
        } catch (error) {
            console.error('Error al añadir tarea:', error);
        }
        
  };

  const handleUpdateTask = async (taskData) => {
    console.log(taskData); // Aquí integrarías la lógica para guardar la tarea
    setIsModalOpen(false); // Cerrar el modal después de guardar
        try {
            const upTask = await updateTaskWithTags(taskData['id'],taskData);
            console.log("tarea recien subida: ",upTask);
            actualizarTareas(upTask);
            
            // Actualizar la UI aquí o redireccionar al usuario
        } catch (error) {
            console.error('Error al añadir tarea:', error);
        }
        
  };



  useEffect(() => {
   // console.log ("Panel Container:", panelContainerRef);
    if (panelContainerRef.current) {
      setTimeout(() => {
        //panelContainerRef.current.scrollLeft = scrollPosition.x;
        //panelContainerRef.current.scrollTop = scrollPosition.y;
        const { x, y } = scrollPosition;
        window.scrollTo(x, y);
        //const { clientLeft, clientsetTop } = panelContainerRef.current;
        //setScrollPosition({ x: clientLeft, y: clientsetTop });
      }, 0); // Retraso mínimo
      //console.log("zoom al Panel: ", scrollPosition)
    }
  }, [scrollPosition, zoomLevel]);



/*
  useEffect(() => {
    const handleScroll = () => {
      if (panelContainerRef.current) {
        setScrollPosition({ x: window.scrollX , y: window.scrollY  });
        console.log('Scroll position updated:', { x: window.scrollX, y: window.scrollY });
      }
    };
    console.log("entro al useEffect con el panelContainerRef ",panelContainerRef);
    const panel = panelContainerRef.current;
    if (panel) {
      panel.addEventListener('scroll', handleScroll);
      console.log("Listener de scroll añadido.");
    }

    return () => {
      if (panel) {
        panel.removeEventListener('scroll', handleScroll);
        console.log("Listener de scroll removido.");
      }
    };
  }, []);*/

  // Agrega un evento para escuchar el desplazamiento (scroll) en la ventana
window.addEventListener('scroll', () => {
  // Obtiene la posición vertical (eje Y) del desplazamiento
  const verticalPosition = window.scrollY || document.documentElement.scrollTop;

  // Obtiene la posición horizontal (eje X) del desplazamiento
  const horizontalPosition = window.scrollX || document.documentElement.scrollLeft;
  //if (!e.ctrlKey) {
    setScrollPosition({ x: horizontalPosition , y: verticalPosition  });
  //}

  // Registra la posición en la consola
  //console.log(`Posición de la ventana: X: ${horizontalPosition}, Y: ${verticalPosition}`);
});


  if (isLoadingElements) {
    return <div>Cargando elementos...</div>; // O cualquier otro indicador de carga que prefieras
  }

  return (
    <div>
    <button onClick={zoomIn}>{t("acercar")}</button>
    <button onClick={zoomOut}>{t("alejar")}</button>
    
    <PanelContainer id="panel-container"  className="panel-container-class" ref={panelContainerRef} onWheel={handleWheel} zoomlevel={zoomLevel}>
    {!(task?.id===undefined) && task&&
       <IconContainer className="icon-container-class" ref={iconContainerRef} onMouseDown={startDragging} style={{left: `${position.x}px`, top: `${position.y}px` }}>
        <IconButton onClick={() => addElement('text')} title={t('iconDescription.addText')}>
          <FiFileText />
        </IconButton>
        <IconButton onClick={() =>  handleAddImage()} title={t('iconDescription.addImage')}>
          <FiImage />
        </IconButton>
        <IconButton onClick={() => addElement('postit')} title={t('iconDescription.addPostit')}>
          <FiBookmark />
        </IconButton>

          {/* Ícono separado para la acción de guardar */}
          <IconButton onClick={() => {setIsTaskSelectorModalOpen(true);}} style={{ marginLeft: '20px' }}  title={t('iconDescription.openTask')}>
            <FiLayers/>
          </IconButton>
          <IconButton onClick={() => {setModalEditing(false);setIsModalOpen(true);}} style={{ marginLeft: '20px' }} title={t('iconDescription.addTask')}>
            <FiPlus /> {/* Asumiendo FiPlus es tu icono de agregar */}
          </IconButton>
          <IconButton onClick={() => {setModalEditing(true);setIsModalOpen(true); } } style={{ marginLeft: '20px' }} title={t('iconDescription.editTask')}>
            <FiEdit /> {/* Asumiendo FiPlus es tu icono de agregar */}
          </IconButton>
        <IconButton onClick={handleSaveTask} style={{ marginLeft: '20px' }}  title={t('iconDescription.saveTask')}> {/* Ajusta el margen izquierdo según necesites */}
          <FiSave />
        </IconButton>
        <IconButton onClick={handleSavePDF} style={{ marginLeft: '20px' }} title={t('iconDescription.printTask')}>
        <FiPrinter /> {/* O <FiFile /> si prefieres un ícono de PDF */}
      </IconButton>
      </IconContainer>
    }
    {
      ((task?.id===undefined) || !task) &&
      <IconContainer className="icon-container-class" ref={iconContainerRef} onMouseDown={startDragging} style={{left: `${position.x}px`, top: `${position.y}px` }}>
        <IconButton onClick={() => {setIsTaskSelectorModalOpen(true);}} style={{ marginLeft: '5px' }} title={t('iconDescription.openTask')}>
            <FiLayers/>
          </IconButton>
        <IconButton onClick={() => {setModalEditing(false);setIsModalOpen(true);}} style={{ marginLeft: '5px' }} title={t('iconDescription.addTask')}>
          <FiPlus /> {/* Asumiendo FiPlus es tu icono de agregar */}
        </IconButton>
    </IconContainer>


    }
  
      
      {elements.map(element => (
         <EditableElement
         key={element.id}
         id={element.id} // Asigna el ID al componente
         element={element}
         onUpdate={(id, newPosition, newSize, newContent) => onUpdateElement(id, newPosition, newSize, newContent)}
         onDelete={() => handleDeleteElement(element.id)}
         zoomlevel={zoomLevel} // Asegúrate de pasar el nivel de zoom actual
       />
      ))}
    </PanelContainer>
    <MiniMap
                scrollPosition={scrollPosition}
                setScrollPosition={(newPos) => {
                  {/*panelContainerRef.current.scrollLeft = newPos.x;
                panelContainerRef.current.scrollTop = newPos.y;*/}
                  setScrollPosition(newPos);
                }}
                canvasSize={canvasSize}
                miniMapSize={{width:158,height:224}}
                backgroundImage={backGrounMiniMap}
            />
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <TaskForm 
            onSaveTask={handleSaveTaskId} 
            onUpdateTask={handleUpdateTask}
            onCancel={handleCloseModal}
            onDelete={handleDelete}
            userName={user.name}
            userId={user.sub}
            InitialTask={ModalEditing? task : null} />
        </Modal>
        <TaskSelectorModal
                isOpen={isTaskSelectorModalOpen}
                onClose={handleCloseTaskSelectorModal}
                onSelectTask={handleSelectTask}
                userId={user.sub}
            />
    <input type="file" accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }} ref={fileInputRef} />
    </div>
  );
};

export default Panel;


/*
        <Rnd
          key={element.id}
          default={{
            x: element.position.x,
            y: element.position.y,
            width: element.size.width,
            height: element.size.height,
          }}
          bounds="parent" // Restringe el movimiento y tamaño dentro del PanelContainer
          enableResizing={element.type !== 'postit'} // Permite redimensionar excepto para post-it
          onDragStop={(e, d) => {
            // Actualiza la posición después de arrastrar
            element.position = { x: d.x, y: d.y };
          }}
          onResizeStop={(e, direction, ref, delta, position) => {
            // Actualiza tamaño y posición después de redimensionar
            element.size = { width: ref.style.width, height: ref.style.height };
            element.position = { x: position.x, y: position.y };
          }}
        >
          <EditableElement element={element} />
        </Rnd>
         onDelete= {handleDeleteElement(element.id)}
        */
