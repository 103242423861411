// TimezoneSelector.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment-timezone';
import { updateUserTimezone } from '../api/supabaseClient'; 


const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  width: 100%;
  max-width: 250px; // Asegura que el sidebar tenga un ancho fijo
`;

const Title = styled.h3`
  margin-bottom: 16px;
  font-size: 18px;
`;

const Select = styled(TextField)`
  width: 100%;
  .MuiInputBase-root {
    font-size: 14px;
  }
  .MuiOutlinedInput-notchedOutline {
    border-width: 2px;
  }
`;

const relevantTimezones = [
  'Pacific/Midway',
  'America/Adak',
  'Pacific/Marquesas',
  'America/Anchorage',
  'America/Los_Angeles',
  'America/Denver',
  'America/Chicago',
  'America/New_York',
  'America/Caracas', // GMT-4
  'America/Santiago', // GMT-4
  'America/St_Johns',
  'America/Sao_Paulo',
  'America/Argentina/Buenos_Aires',
  'America/Noronha',
  'Atlantic/Cape_Verde', // GMT-1
  'Atlantic/Azores',
  'Europe/London',
  'Europe/Berlin',
  'Europe/Athens',
  'Europe/Moscow',
  'Asia/Tehran',
  'Asia/Dubai',
  'Asia/Kabul',
  'Asia/Karachi',
  'Asia/Kolkata',
  'Asia/Kathmandu',
  'Asia/Dhaka',
  'Asia/Yangon',
  'Asia/Bangkok',
  'Asia/Hong_Kong',
  'Asia/Tokyo',
  'Australia/Darwin',
  'Australia/Sydney',
  'Pacific/Norfolk',
  'Pacific/Auckland',
  'Pacific/Chatham',
  'Pacific/Tongatapu',
  'Pacific/Kiritimati'
];

const getTimezoneOffsets = (timezones) => {
  return timezones.map(tz => {
    const offset = moment.tz(tz).utcOffset();
    const sign = offset >= 0 ? '+' : '-';
    const hours = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;
    return {
      label: tz,
      offset: `GMT${sign}${hours}:${minutes.toString().padStart(2, '0')}`
    };
  });
};


const timezonesWithOffsets = getTimezoneOffsets(relevantTimezones);

/*
const relevantTimezones = [
  { label: 'Pacific/Midway', offset: 'GMT-11:00' },
  { label: 'America/Adak', offset: 'GMT-10:00' },
  { label: 'Pacific/Marquesas', offset: 'GMT-09:30' },
  { label: 'America/Anchorage', offset: 'GMT-09:00' },
  { label: 'America/Los_Angeles', offset: 'GMT-08:00' },
  { label: 'America/Denver', offset: 'GMT-07:00' },
  { label: 'America/Chicago', offset: 'GMT-06:00' },
  { label: 'America/New_York', offset: 'GMT-05:00' },
  { label: 'America/Caracas', offset: 'GMT-04:30' },
  { label: 'America/Santiago', offset: 'GMT-04:00' },
  { label: 'America/St_Johns', offset: 'GMT-03:30' },
  { label: 'America/Argentina/Buenos_Aires', offset: 'GMT-03:00' },
  { label: 'America/Noronha', offset: 'GMT-02:00' },
  { label: 'Atlantic/Azores', offset: 'GMT-01:00' },
  { label: 'Europe/London', offset: 'GMT+00:00' },
  { label: 'Europe/Berlin', offset: 'GMT+01:00' },
  { label: 'Europe/Athens', offset: 'GMT+02:00' },
  { label: 'Europe/Moscow', offset: 'GMT+03:00' },
  { label: 'Asia/Tehran', offset: 'GMT+03:30' },
  { label: 'Asia/Dubai', offset: 'GMT+04:00' },
  { label: 'Asia/Kabul', offset: 'GMT+04:30' },
  { label: 'Asia/Karachi', offset: 'GMT+05:00' },
  { label: 'Asia/Kolkata', offset: 'GMT+05:30' },
  { label: 'Asia/Kathmandu', offset: 'GMT+05:45' },
  { label: 'Asia/Dhaka', offset: 'GMT+06:00' },
  { label: 'Asia/Yangon', offset: 'GMT+06:30' },
  { label: 'Asia/Bangkok', offset: 'GMT+07:00' },
  { label: 'Asia/Hong_Kong', offset: 'GMT+08:00' },
  { label: 'Asia/Tokyo', offset: 'GMT+09:00' },
  { label: 'Australia/Darwin', offset: 'GMT+09:30' },
  { label: 'Australia/Sydney', offset: 'GMT+10:00' },
  { label: 'Pacific/Norfolk', offset: 'GMT+11:00' },
  { label: 'Pacific/Auckland', offset: 'GMT+12:00' },
  { label: 'Pacific/Chatham', offset: 'GMT+12:45' },
  { label: 'Pacific/Tongatapu', offset: 'GMT+13:00' },
  { label: 'Pacific/Kiritimati', offset: 'GMT+14:00' }
];*/

const TimezoneSelector = ({ initialTimezone, onTimezoneChange, usuario}) => {
  const [selectedTimezone, setSelectedTimezone] = useState(initialTimezone);

  const handleTimezoneChange = async (event) => {
    const newTimezone = event.target.value;
    setSelectedTimezone(newTimezone);
    onTimezoneChange(newTimezone); // Pass new timezone back to the parent
    console.log("user: ",usuario)
    await updateUserTimezone(usuario.id_usuario, newTimezone); 
  };

  useEffect(() => {
    setSelectedTimezone(initialTimezone);
  }, [usuario]);

  return (
    <SidebarContainer>
      <Title>Select Timezone</Title>
      <Select
        select
        label="Choose Timezone"
        value={selectedTimezone}
        onChange={handleTimezoneChange}
        variant="outlined"
      >
        {timezonesWithOffsets.map(({ label, offset }) => (
          <MenuItem key={label} value={label}>
            {`${label} (${offset})`}
          </MenuItem>
        ))}
      </Select>
    </SidebarContainer>
  );
};

export default TimezoneSelector;
