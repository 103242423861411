
export const eventStyleGetter = (event, start, end, isSelected) => {
    let style = {
      backgroundColor: event.color || '#3174ad',
      borderRadius: '5px',
      opacity: 1,
      color: 'white',
      border: '0px',
      display: 'block',
      padding: '1px 2px',
      marginTop: '0px',
      fontSize: '1em', // Tamaño de fuente base, considera aumentarlo para pantallas pequeñas
    };
    if (window.innerWidth <= 600) {
      style = {
        ...style, // Conserva los estilos base
        fontSize: '0.8em', // Aumenta el tamaño de fuente para pantallas pequeñas
        padding: '1px 2px', // Aumenta el padding para hacer los eventos más fáciles de tocar
      };
    }
    if (event.isCompleted) {
      style.opacity = 0.3; // Esto opacará los eventos pasados
      // Puedes agregar aquí más estilos para eventos pasados
    }
    else if (new Date(event.end) < new Date() && !event.isCompleted)
    {
      style.opacity = 0.8; 
    }
    return { style: style };
  };
  