import { addDays, addWeeks, addMonths, format, isWithinInterval,setHours, setMinutes  } from 'date-fns';
import { es } from 'date-fns/locale';

function combineDateWithTime(date, timeString) {
    const [hours, minutes] = timeString.split(':').map(Number);
    return setMinutes(setHours(date, hours), minutes);
}

function convertirfecha(Fecha_string)
{
    let year = Fecha_string.substring(0, 4);
    let month = Fecha_string.substring(5, 7);
    let day = Fecha_string.substring(8, 10);

    let yearNum = parseInt(year);
    let monthNum = parseInt(month) - 1; // Restar 1 porque los meses en JavaScript comienzan desde 0
    let dayNum = parseInt(day);

    return new Date(yearNum,monthNum,dayNum);

}

export function generateSeriesEvents(seriedb, maxMonths = 6, serieData, eventoRef) {
    const events = [];
    console.log("tipo de start_date", typeof serieData.startDate,serieData.startDate)
    console.log("tipo de end_date", typeof serieData.endDate,serieData.endDate)
    //console.log("tipo de start_date", typeof seriedb.start_date)
    let currentDate = convertirfecha(serieData.startDate);
    let enddatedato =convertirfecha(serieData.endDate);
    const start_date_p = currentDate
    //let currentDate =serieData.startDate;
    //let enddatedato =serieData.end_date;


    const endDate = addMonths(currentDate, maxMonths);
    let termino = endDate<=enddatedato? endDate:enddatedato;
    let iteraciones=0;




    while (isWithinInterval(currentDate, { start: start_date_p, end: termino })) {
        if (iteraciones>=seriedb.repeat_count)
        {
            return events;
        }

        const dayOfWeek = format(currentDate, 'EEEE', { locale: es }); // Esto devolverá el día de la semana en español, por ejemplo 'lunes'
        const eventStart = combineDateWithTime(currentDate, serieData.startTime);
        const eventEnd = combineDateWithTime(currentDate, serieData.endTime);


        console.log("el día de la semana propuesto es:", dayOfWeek);
        if (seriedb.days_of_week.toLowerCase().includes(dayOfWeek)) {
            events.push({
                id:eventoRef.id,
                title: eventoRef.titulo,
                start: eventStart,
                end: eventEnd, // Ajustar si el evento dura más de un día
                color:eventoRef.color,
                description:eventoRef.descripcion,
                serie_id: eventoRef.serie_id,
                serie:serieData,
                // Otros detalles necesarios
            });
            iteraciones=iteraciones+1;
        }

        switch (serieData.repeat_type) {
            case 'daily':
                currentDate = addDays(currentDate, serieData.interval);
                break;
            case 'weekly':
                currentDate = addWeeks(currentDate, serieData.interval);
                break;
            case 'monthly':
                currentDate = addMonths(currentDate, serieData.interval);
                break;
            // Agregar más casos si hay otros tipos de repetición
        }
        
    }

    return events;
}

export default generateSeriesEvents;
