// ChatPopup.js
import React, { useState,useRef,useEffect} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {guardarMensaje,obtenerHistorialChat} from '../api/tasksApi';

const Container = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 300px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
`;

const MessageContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
  margin-bottom: 10px; // Asegúrate de dejar espacio para el InputContainer
`;


const Header = styled.div`
  background-color: #007bff;
  color: white;
  padding: 10px;
  cursor: pointer;
`;

const Content = styled.div`
  padding: 10px;
  display: ${props => props.isVisible ? 'block' : 'none'};
`;

const InputContainer = styled.div`
  display: flex;
  padding-top: 10px;
`;

const Input = styled.input`
  flex-grow: 1;
  margin-right: 10px;
  padding: 5px;
`;

const Button = styled.button`
  padding: 5px 10px;
`;



function ChatPopup(user,task) {
    const [isVisible, setIsVisible] = useState(false);
    const [input, setInput] = useState('');
    const [mensajes, setMensajes] = useState([]);
  
    const toggleVisibility = () => setIsVisible(!isVisible);
    const handleInputChange = (e) => setInput(e.target.value);
    const endOfMessagesRef = useRef(null);
    const { t } = useTranslation();

  /*  useEffect(() => {
      // Si 'historial' está presente y no está vacío, mapea y actualiza 'mensajes'
      if (historial && !cargandoHistorial) {
          console.log(("historial: ",historial));
          const mensajesFormateados = historial.map(chat => ({
              emisor: chat.sender, // Asume que 'sender' es 'usuario' o 'entrenador'
              texto: chat.message
          }));
          setMensajes(mensajesFormateados);
      } else {
          // Si 'historial' está vacío o no está definido, puedes elegir dejar 'mensajes' vacío o realizar alguna otra acción
          console.log('Historial aún no está disponible o está vacío.');
          setMensajes([]);
      }
  }, [historial,cargandoHistorial]);*/


      // Carga el historial cuando el componente se monta o cuando el usuario cambia
  useEffect(() => {
    const user_id=user.user.sub;
    const cargarHistorial = async () => {
      if (user.user && user_id) {
        try {
          const historialCargado = await obtenerHistorialChat(user_id);
          console.log("hitorial: ",historialCargado);
                  // Transforma el historial al formato adecuado
          const historialFormateado = historialCargado.reverse().map(chat => {
          // Asumiendo que cada objeto en tu historial tiene las propiedades 'sender' y 'message'
            return {
              emisor: chat.sender === 'usuario'? 'usuario' : 'entrenador', // Cambia 'usuario' por cómo identificas los mensajes del usuario
              texto: chat.message
            };
          });
          console.log("hitorial formateado: ",historialFormateado);
          setMensajes(historialFormateado);
        } catch (error) {
          console.error("Error al cargar el historial de chat:", error);
          // Maneja el error como creas conveniente
        }
      }
    };

    cargarHistorial();
  }, [user,task]);  
    useEffect(() => {
        endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
      }, [mensajes]);
  
    const enviarMensajeAChatGPT = async (mensaje,HistorialC) => {
      const payload = {
        messages: HistorialC.concat( [
            {
              role: "system",
              content: "Tú eres un entrenador de productividad ayudando al usuario con el conocimiento, experiencia y prácticas de Tim Ferris, Nir Eyal y James Clear. Respondes con no más de 100 palabras motivantes y practicas."
            },
            {
              role: "user",
              content: mensaje,
            }
          ]),
        temperature: 0.5,
        max_tokens: 256,
        top_p: 0.5,
        frequency_penalty: 0.5,
        presence_penalty: 0.6,
        model: "gpt-4-0125-preview", // Asegúrate de usar el modelo y los parámetros deseados
      };
  
      try {
        const response = await axios.post(
          'https://api.openai.com/v1/chat/completions',
          payload,
          { headers: { 'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}` } }
        );
        return response.data.choices[0].message.content.trim();
      } catch (error) {
        console.error('Error al enviar mensaje a ChatGPT:', error.response);
        return "Lo siento, hubo un error al procesar tu solicitud.";
      }
    };
  /*
    const handleSendClick = async () => {
      const mensajeUsuario = input;
      // Primero, añade el mensaje del usuario
      setMensajes(mensajesAnteriores => [...mensajesAnteriores, { emisor: 'usuario', texto: mensajeUsuario }]);
      setInput(''); // Limpia el input después de enviar el mensaje del usuario
    
      try {
        const respuestaChatGPT = await enviarMensajeAChatGPT(mensajeUsuario);
        // Asegúrate de añadir la respuesta de ChatGPT basándote en el estado actualizado
        setMensajes(mensajesAnteriores => [...mensajesAnteriores, { emisor: 'chatgpt', texto: respuestaChatGPT }]);
      } catch (error) {
        console.error('Error al enviar mensaje a ChatGPT:', error);
        // Considera manejar el error de alguna manera, como mostrar un mensaje al usuario
      }
    };*/

    const handleSendClick = async () => {
      const mensajeUsuario = input;
      // Suponiendo que tengas una función para obtener el ID del usuario actual
      console.log("usuario: ",user.user);
      const userId = user.user.sub; 
      
      setInput('');

          // Obtén los últimos 5 mensajes de cada parte para usar como contexto
      const ultimoContexto = mensajes.slice(-10).map(mensaje => ({
        role: mensaje.emisor === 'usuario' ? 'user' : 'assistant', // Ajusta según cómo estén definidos tus roles
        content: mensaje.texto
      }));

      setMensajes(mensajesAnteriores => [...mensajesAnteriores, { emisor: 'usuario', texto: mensajeUsuario }]);
  
      const respuestaChatGPT = await enviarMensajeAChatGPT(mensajeUsuario,ultimoContexto);
      await guardarMensaje(userId, mensajeUsuario, 'usuario');
      await guardarMensaje(userId, respuestaChatGPT, 'chatgpt');
      
      // Actualización de la UI con los mensajes nuevos
      setMensajes(mensajesAnteriores => [...mensajesAnteriores, { emisor: 'chatgpt', texto: respuestaChatGPT }]);
    };
    
  
    return (
        <Container>
          <Header onClick={toggleVisibility}>{t("Chat Trainer")}</Header>
          <Content isVisible={isVisible}>
          <MessageContainer>
              {mensajes.map((msg, index) => (
               <div key={index} style={{ textAlign: msg.emisor === 'usuario' ? 'right' : 'left', margin: '5px 0' }}>
                <strong>{msg.emisor === 'usuario' ? t("Tú") : t('Entrenador')}:</strong>
                <p style={{ display: 'inline', marginLeft: '5px' }}>{msg.texto}</p>
              </div>
             ))}
            <div ref={endOfMessagesRef} />
          </MessageContainer>
            <InputContainer>
              <Input value={input} onChange={handleInputChange} placeholder={t("Write Your Message")}/>
              <Button onClick={handleSendClick}>Enviar</Button>
            </InputContainer>
          </Content>
        </Container>
      );
    };
  
  export default ChatPopup;