import React, { useState,useEffect } from 'react';
//import MiCalendario from './MiCalendario';
import MiCalendario from './components/MiCalendario/MiCalendario';
import { useAuth0 } from "@auth0/auth0-react";
import './App.css';
import styled from 'styled-components';
import Agenda from './Agenda';
//import LoginButton from "./components/User/login";
//import LogoutButton from "./components/User/logout";
//import Profile from "./components/User/profile";
import Sidebar from "./components/sideBar/Sidebar2";
import LandingPage from './public/TimeFitHomePage'; // Importa el nuevo componente
import { manageUser } from './components/api/supabaseClient';
import StyledButton from './components/design/StyledButton';
import { DimensionProvider } from './DimensionContext'; // Ajusta la ruta según sea necesario
import { I18nextProvider } from 'react-i18next';
import i18n from './components/Idioma/i18n';
import TaskForm from './components/TaskManager/TaskForm';
import Modal from './components/design/Modal'; // Asegúrate de que la ruta sea correcta
import { saveTaskWithTags } from './components/api/tasksApi'; 
import TaskDriver from './components/TaskManager/TaskDriver';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import VideoPage from './public/Videos/VideoPage'; // Asegúrate de importar tu nuevo componente
import TaskSelectorModal from './components/TaskManager/TaskSelectorModal';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useUserContext } from './components/User/UserContext';
import Dashboard from './components/Games/Dashboard';
import './components/design/Dashboard.css';

//import { useMobileContext } from './MobileContext';



const Container = styled.div`
  padding: 20px;
  margin: 0 auto;
  max-width: auto;
`;




function App() {
  const agenda = new Agenda();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const { isAuthenticated,loginWithRedirect, logout,user,getAccessTokenSilently } = useAuth0();
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(isAuthenticated);
  //const [usuario_c, setUsuario] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCloseModal = () => setIsModalOpen(false);
  const [currentView, setCurrentView] = useState('calendar');

  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isTaskSelectorModalOpen, setIsTaskSelectorModalOpen] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [isCtrlPressed, setIsCtrlPressed] = useState(false);
  const { t } = useTranslation();
  const { setTimefitUser } = useUserContext();
  //const { isMobile } = useMobileContext();

  const handleSelectTask = (task,tasks) => {
    setSelectedTaskId(task.id);
    setSelectedTask(task);
    setTasks(tasks)
    setCurrentView('TaskManager')

    console.log("taskId,task, tasks:",selectedTaskId,selectedTask,tasks);
    // Cambia la vista a TaskDriver o actualiza el estado según sea necesario
  };

  const handleOpenTaskSelector = () => {
    setIsTaskSelectorModalOpen(true);
  };
  const handleCloseTaskSelectorModal = () => {
    setIsTaskSelectorModalOpen(false);
  };

  
  const handleSaveTask = async (taskData) => {
    console.log(taskData); // Aquí integrarías la lógica para guardar la tarea
    setIsModalOpen(false); // Cerrar el modal después de guardar
        try {
            await saveTaskWithTags(taskData);
            // Actualizar la UI aquí o redireccionar al usuario
        } catch (error) {
            console.error('Error al añadir tarea:', error);
        }
  };

 

  useEffect(() => {
    setIsUserAuthenticated(isAuthenticated);
  }, [isAuthenticated]); // Dependencia: isAuthenticated


  useEffect(() => {
  if (isAuthenticated) {
    console.log('ID del usuario:', user);
    //console.log(process.env.REACT_APP_OPENAI_API_KEY);
    const cargarDatos = async () => {
      try {
          const datosUsuario = await manageUser(user); // Asumiendo que manageUser es una función asíncrona
          console.log('Datos del usuario:', datosUsuario);
          //setUsuario(datosUsuario);
          setTimefitUser(datosUsuario);
          // Haz algo con el usuario
      } catch (error) {
          console.error('Error al cargar los datos del usuario:', error);
      }
  };

  cargarDatos();
    

    // Aquí puedes usar user.sub como identificador único del usuario
  }
}, [isAuthenticated, user]);

useEffect(() => {
  
}, []); // Dependencias vacías para que se ejecute solo al montar el componente

useEffect(() => {
  const handleKeyDown = (e) => {
    if (e.key === "Control") {
      setIsCtrlPressed(true);
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === "Control") {
      setIsCtrlPressed(false);
    }
  };

  const handleWheel = (e) => {
    if (isCtrlPressed) {
      e.preventDefault();
      // Coloca aquí la lógica para ajustar el zoom
    }
  };

  window.addEventListener('keydown', handleKeyDown);
  window.addEventListener('keyup', handleKeyUp);
  window.addEventListener('wheel', handleWheel, { passive: false });

  return () => {
    window.removeEventListener('keydown', handleKeyDown);
    window.removeEventListener('keyup', handleKeyUp);
    window.removeEventListener('wheel', handleWheel);
  };
}, [isCtrlPressed]);


  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  const stage = 1;
  const medalCount = 5;
  const detailedInfo = [
    'Detalles de etapa 1',
    'Has ganado 5 medallas por ahora',
    // Agrega más información según sea necesario
  ];
  


  return (
    
<DndProvider backend={HTML5Backend}>
<I18nextProvider i18n={i18n}>
<DimensionProvider>

<Router>
      <Routes>
      <Route path="/video" element={<VideoPage />} />
        </Routes>
    </Router>
    <Container className="App" id="modal-root">
      {/*<Dashboard stage={stage} medalCount={medalCount} detailedInfo={detailedInfo} />*/}
      <StyledButton size="small" className="toggle-button" onClick={toggleSidebar}>☰</StyledButton>
      <Sidebar isVisible={isSidebarVisible} toggleSidebar={toggleSidebar} onSelectTask={handleSelectTask} onSelectView={setCurrentView} />
      {/*selectedTaskId && <TaskDriver tasks={Tasks}/>*/}

      {isAuthenticated ? (
        currentView ==='calendar' ? (
        <MiCalendario agenda={agenda} />):
        (selectedTaskId && <TaskDriver tasks={tasks} TaskID={selectedTaskId} user={user}/>)

      ) : (
        <LandingPage />
      )}

      {!isAuthenticated && (
        <StyledButton onClick={() => loginWithRedirect()}>{t("loginButton")}</StyledButton>
      )}

      {isAuthenticated &&(
        <>
        <StyledButton onClick={() => setIsModalOpen(true)}>{t("NewTask")}</StyledButton>
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <TaskForm 
            onSaveTask={handleSaveTask} 
            onCancel={handleCloseModal}
            userName={user.name}
            userId={user.sub} />
          </Modal>
        <StyledButton onClick={handleOpenTaskSelector}>{t("OpenTask")}</StyledButton>
        <TaskSelectorModal isOpen={isTaskSelectorModalOpen} onClose={handleCloseTaskSelectorModal} onSelectTask={handleSelectTask} userId={user.sub} />

        <StyledButton onClick={() => logout({ returnTo: window.location.origin })}>
          Cerrar Sesión
        </StyledButton>
        </>
      )}


      

    </Container>

   
    </DimensionProvider>
    </I18nextProvider>
    </DndProvider>

  );
}



export default App;
