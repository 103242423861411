import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://qmaprgchglwiuhjejzua.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFtYXByZ2NoZ2x3aXVoamVqenVhIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDU2MDI2NzAsImV4cCI6MjAyMTE3ODY3MH0.YadSIgW19BmA-eznc1mo50GOD67vx5J9BFee_nB3g5s';

const supabase = createClient(supabaseUrl, supabaseAnonKey);

function guessUserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}


const userExists = async (userId) => {
    const { data, error } = await supabase
      .from('usuarios')
      .select('*')
      .eq('id_usuario', userId)

    if (Object.keys(data).length == 0) {
      return null;
    }
  
    return data[0];
  };
  
  const crearUsuario = async (usuarioData) => {
    const { sub, name, email } = usuarioData;


    // Establecer la zona horaria por defecto o tomarla del sistema
    const zonaHorariaDefault = guessUserTimezone();
  
    try {
      const { data, error } = await supabase
        .from('usuarios')
        .insert([
          {
            id_usuario: sub,
            nombre: name,
            correo: email? email:'',
            zona_horaria: zonaHorariaDefault
          }
        ])
        .single()
        .select();
  
      if (error) throw error;
  
      return data;
    } catch (error) {
      console.error('Error al crear el usuario:', error);
      throw error;
    }
  };

  export const manageUser = async (auth0User) => {
    if (!auth0User) {return null;}
    console.log('ID del usuario check:', auth0User);
    let user = await userExists(auth0User.sub);

    console.log('ID del usuario check_app:', user);
    //console.log('lenght de usuario:', Object.keys(user).length);
  
    if (!user) {
      const userT = await crearUsuario(auth0User);
      user=userT;
      console.log('usuario nuevo', user);
    }
  
    return user;
  };
  
  
  export const updateUserTimezone = async (userId, newTimezone) => {
    try {
      const { data, error } = await supabase
        .from('usuarios') // Reemplaza 'users' con el nombre de tu tabla
        .update({ zona_horaria: newTimezone })
        .eq('id_usuario', userId);
  
      if (error) {
        throw error;
      }
  
      console.log('User timezone updated:', data);
    } catch (error) {
      console.error('Error updating user timezone:', error);
    }
  };
  


export default supabase;

