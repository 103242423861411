// Sidebar.js
import React, { useEffect, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import {
  SidebarContainer,
  CloseButtonContainer,
  UserSection,
  ConfigSection,
  NavSection,
  StyledButton,
  LogoutSection,
  Version
} from './SidebarStyles';
import Profile from "../User/profile";
import LanguageSwitcher from "../Idioma/LanguageSwitcher";
import TimezoneSelector from '../Idioma/TimezoneSelector';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../User/UserContext';

const Sidebar = ({ isVisible, toggleSidebar, onSelectView }) => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
  const sidebarRef = useRef(); // Ref para el sidebar
  const { t } = useTranslation();
  const { timefitUser, setTimefitUser } = useUserContext();

  const handleSidebarClick = (event) => {
    event.stopPropagation();
  };

  const handleTimezoneChange = (newTimezone) => {
    setTimefitUser((prevUser) => ({
      ...prevUser,
      zona_horaria: newTimezone,
    }));
    // Optionally update the backend with the new timezone
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target) && isVisible) {
        toggleSidebar(); // Cierra el sidebar si se hace clic fuera
      }
    };

    // Añadir y remover el listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isVisible, toggleSidebar]);

  return (
    <SidebarContainer ref={sidebarRef} isVisible={isVisible} onClick={handleSidebarClick}>
      <CloseButtonContainer>
        <IconButton onClick={toggleSidebar}>
          <CloseIcon />
        </IconButton>
      </CloseButtonContainer>
      
      {isAuthenticated && (
        <>
          <UserSection>
            <Profile />
          </UserSection>

          <ConfigSection>
            <LanguageSwitcher />
            {timefitUser ? (
              <TimezoneSelector initialTimezone={timefitUser.zona_horaria} onTimezoneChange={handleTimezoneChange} usuario ={timefitUser}/>
            ) : (
              <p>Loading user data...</p>
            )}
          </ConfigSection>

          <NavSection>
            <StyledButton variant="contained" onClick={() => onSelectView('calendar')}>
              {t("Calendar")}
            </StyledButton>
            <StyledButton variant="contained" onClick={() => onSelectView('taskDriver')}>
              {t("TaskDriver")}
            </StyledButton>
          </NavSection>

          <LogoutSection>
            <StyledButton variant="contained" onClick={() => logout({ returnTo: window.location.origin })}>
              {t("logoutButton")}
            </StyledButton>
            <Version>
              v1.01 Android
            </Version>
          </LogoutSection>
        </>
      )}

      {!isAuthenticated && (
        <StyledButton variant="contained" onClick={() => loginWithRedirect()}>
          {t("loginButton")}
        </StyledButton>
      )}
    </SidebarContainer>
  );
};

export default Sidebar;
