import React, { useState,useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment-timezone';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './miEstiloPersonalizado.css';
import EventForm from '../EventForm/EventForm'; // Asegúrate de importar correctamente el EventForm
import { eventStyleGetter } from './utils'; 
import { getEvents, deleteEvent,updateEvent, getEvents_series, updateSerie, getEventById, deleteSerie} from '../api/eventsApi';
import {generateSeriesEvents} from './CreateSeries';
import 'moment/locale/es'; // Español
import 'moment/locale/en-gb'; // Inglés
import { useTranslation } from 'react-i18next';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import styled from 'styled-components';
import FirstTimeModal from '../Documentation/FirstTimeModal.js';
import {combinarFechaHora, separarFechaHora,convertirFechaUTCALocal } from '../Idioma/DateUtils.js'
import { useMobileContext } from '../../MobileContext';
// eslint-disable-next-line
import { IoCheckmarkDoneSharp } from 'react-icons/io5'; 
import { useUserContext } from '../User/UserContext.js';

moment.locale('es'); // Establece el locale a español
//const zonaHoraria=moment.tz.setDefault("America/Santiago");


// eslint-disable-next-line
const localizer = momentLocalizer(moment);
/*
const zonaHoraria="America/Santiago";

function convertirFechaUTCALocal(fechaUTC, zonaHoraria) {
  return moment.utc(fechaUTC).tz(zonaHoraria).toDate();
}

function convertirLocalAUTC(fechaHoraLocal, zonaHoraria) {
  // Crea un objeto moment con la fecha y hora local en la zona horaria especificada
  const fechaMoment = moment.tz(fechaHoraLocal, zonaHoraria);

  // Convierte la fecha y hora al formato UTC
  const fechaUTC = fechaMoment.utc().toDate();

  // Retorna la fecha y hora en formato UTC
  return fechaUTC;
}
*/
const messagesInSpanish = {
  allDay: 'Todo el día',
  previous: 'Anterior',
  next: 'Siguiente',
  today: 'Hoy',
  month: 'Mes',
  week: 'Semana',
  day: 'Día',
  agenda: 'Agenda',
  date: 'Fecha',
  time: 'Hora',
  event: 'Evento', // Dependiendo de tus necesidades, puedes añadir o modificar más mensajes aquí
};

const messagesInEnglish = {
  allDay: 'All day',
  previous: 'Previous',
  next: 'Next',
  today: 'Today',
  month: 'Month',
  week: 'Week',
  day: 'Day',
  agenda: 'Agenda',
  date: 'Date',
  time: 'Time',
  event: 'Event', // Similar to the Spanish version, customize as needed
};
const EventContainer = styled.div`
  background-color: ${props => props.isCompleted ? `rgba(${props.colorRgb}, 0.3)` : `rgb(${props.colorRgb})`};
  color: ${props => props.isCompleted ? '#a3b1c6' : 'white'};
  padding: 0px 4px;
  border-radius: 3px;
  display: flex;
  justify-content: center; 
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  height: 100%;
  position: relative;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => `rgba(${props.colorRgb}, 0.2)`};
  }
`;

// Asumiendo que el color se pasa como 'rgb' desde las propiedades del evento.
// Estilos para el contenedor del evento usando styled-components


const EventDetails = styled.div`
  flex: 4; // Ocupa 4 quintos del contenedor
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-items: flex-start;
  flex-direction: row; 
  display: flex;
`;

const CompletedIcon = styled.span`
  color: green;
  font-size: 1em;
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ffffff;
  visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
  &:before {
    content: "\\2713";
  }
`;
/*
const CompletedIcon = styled.span`
  color: green;
  font-size: 1em;  // Adecuado para ser visible pero no dominante
  margin-right: 4px;  // Espacio entre el ícono y el texto
  visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
  &:before {
    content: "\\2713"; // Unicode para una marca de verificación
  }
`;*/

const SmallEventTitle = styled.span`
  font-size: 0.7rem; // Tamaño reducido
  font-weight: 400; // Más delgado
  margin: 0; // Sin margen
  padding: 0; // Sin padding
  √ // Centrado horizontalmente
  justify-content: center; 
  overflow: hidden;
  white-space: nowrap;
  height: 100%;
  text-overflow: ellipsis; // Añade puntos suspensivos si es necesario
`;

const EventTitle = styled.span`
  font-weight: bold;
  font-size: 0.7em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  align-self: flex-start; /* Alinea el título en la parte superior */
  margin-top: 1px;
  justify-content: center; 
`;



const EventTime = styled.span`
  font-size: 0.6em;
  color: rgba(255, 255, 255, 0.75);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

`;

/*const EventCheckboxArea = styled.div`
  flex: 1; // Ocupa un tercio del contenedor
  display: flex;
  align-items: center;
  width:100%;
  justify-content: center;
  background-color: transparent; // Inicialmente transparente
  transition: background-color 0.3s ease-in-out;
  visibility: ${props => props.series ? 'hidden' : 'visible'}; 
  &:hover {
    background-color: ${props => `rgba(${props.colorRgb}, 0.3)`}; // Cambio sutil para indicar interactividad
  }
`;*/
/*const EventCheckboxArea = styled.div`
position: relative; // Necesario para contener correctamente el checkbox posicionado absolutamente
flex: 1;
display: flex;
align-items: center;
justify-content: center;
height: 100%; // Asegúrate de que tiene altura suficiente
width: 100%; // Asegúrate de que tiene ancho suficiente para manejar interacciones
background-color: transparent;
  &:hover {
    background-color: ${props => `rgba(${props.colorRgb}, 0.3)`}; // Cambio sutil para indicar interactividad
  }
`;*/

const EventCheckboxArea = styled.div`
  position: relative; // Necesario para el posicionamiento absoluto del checkbox
  display: flex;
  flex: 1;
  top: 0px; // Posición desde arriba
  right: 0px; // Posición desde la derecha
  width: 40px; // Ancho suficiente para el checkbox
  height: ${props => props.series ? '0' : 'auto'};
  min-height: 10px;
  align-items: center; // Centra verticalmente
  justify-content: center; // Centra horizontalmente
  width: 100%; // Ocupa todo el ancho disponible
  visibility: ${props => props.series ? 'hidden' : 'visible'}; // Oculta si es parte de una serie
  cursor: pointer; // Indica que el área es interactiva
  transition: background-color 0.3s ease; // Suaviza la transición del color de fondo
  &:hover {
    background-color: rgba(255, 255, 255, 0.2); // Cambio sutil en el fondo al pasar el mouse
  }
`;

/*
const EventCheckbox = styled.input`
  visibility: hidden; // Invisible inicialmente
  cursor: pointer;
  ${EventCheckboxArea}:hover & {
    visibility: visible; // Visible solo durante hover
    cursor: pointer; // Cambiar el cursor para indicar interactividad
  }
`;*/
/*const EventCheckbox = styled.input`
  display: none; // No ocupa espacio cuando no se muestra
  position: absolute; // Posicionamiento absoluto
  top: 0px; // Ajusta la posición vertical como necesites
  left: 2px; // Ajusta la posición horizontal como necesites
  cursor: pointer;
  ${EventCheckboxArea}:hover & {
    display: block; // Mostrar solo durante hover
    cursor: pointer; // Indicar interactividad
  }
`;*/
/*const EventCheckbox = styled.input`
  opacity: 0; // Transparente cuando no es interactivo
  position: absolute; // Posicionamiento absoluto
  top: 10px; // Ajusta la posición vertical como necesites
  left: 10px; // Ajusta la posición horizontal como necesites
  z-index: 10; // Asegura que el checkbox esté sobre otros elementos
  cursor: pointer;
  ${EventCheckboxArea}:hover & {
    opacity: 1; // Hacer visible cuando el área es hovereada
    cursor: pointer; // Cambiar el cursor para indicar interactividad
  }
`;*/
const EventCheckbox = styled.input`
  type: 'checkbox';
  opacity: 0; // Inicia invisible
  position: absolute;
  cursor: pointer;
  min-heigh :10px;
  z-index: 10; // Asegura que esté por encima de otros elementos
  transition: opacity 0.3s ease, transform 0.3s ease; // Transición suave para la opacidad y transformación
  box-shadow: none; // Sin sombra cuando está inactivo

  ${EventCheckboxArea}:hover & {
    opacity: 1; // Hace visible el checkbox durante el hover
    transform: scale(1.1); // Efecto de aumento para destacar
    box-shadow: 0 0 10px rgba(0,0,0,0.5); // Sombra para mejorar la visibilidad
  }
`;




const EventTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;



const MyEvent = ({ event,currentView, onToggleCompleted }) => {
  const durationInMinutes = (new Date(event.end) - new Date(event.start)) / (1000 * 60);
  const showEnd = durationInMinutes >= 30; // Mostrar la hora de fin solo para eventos de 30 minutos o más
  const { isMobile } = useMobileContext();

  return (
  <EventContainer isCompleted={event.isCompleted} colorRgb={event.color}>
    <EventDetails>
      {event.isCompleted && <CompletedIcon isVisible />}
      {showEnd &&
      <EventTitle>{event.title}</EventTitle>}
      {!showEnd &&
       <SmallEventTitle>{event.title}</SmallEventTitle>}
      {!event.isCompleted && showEnd &&
              <EventTimeWrapper>
              <EventTime>{event.start.toLocaleTimeString()}</EventTime>
              <EventTime>{event.end.toLocaleTimeString()}</EventTime>
            </EventTimeWrapper>}
    </EventDetails>
    <EventCheckboxArea
      series={event.serie && Object.keys(event.serie).length > 0}
    >
      {(!isMobile || currentView==='day')&&
      <EventCheckbox 
        type='checkbox'
        checked={event.isCompleted}
        onChange={(e) => onToggleCompleted(event, e)}
        onClick={(e) => e.stopPropagation()} 
        
      />}
    </EventCheckboxArea>
  </EventContainer>);
};



function createSerieObject(serieData,timefitUser) {

  const {fecha:fecha_inicio, hora:hora_inicio} = separarFechaHora(convertirFechaUTCALocal(combinarFechaHora(serieData.start_date,serieData.start_time),timefitUser.zona_horaria));
  const {fecha:fecha_fin,hora:hora_fin} = separarFechaHora(convertirFechaUTCALocal(combinarFechaHora(serieData.end_date,serieData.end_time),timefitUser.zona_horaria));
  console.log("inicio serie:",fecha_inicio, hora_inicio);
  console.log("fin Serie:",fecha_fin, hora_fin);
  return {
    serie_id: serieData.serie_id || null,
    startDate: fecha_inicio || null,
    endDate: fecha_fin || null,
    startTime: hora_inicio || null,
    endTime: hora_fin || null,
    daysOfWeek: serieData.days_of_week?serieData.days_of_week.split(',') : [],
    repeatCount:serieData.repeat_count|| null, 
    repeat_type:serieData.repeat_type|| null, 
    interval:serieData.interval|| null, 
    // ...otros campos necesarios
  };
}

function transformarEventoDbACalendario(eventoDb) {
  return {
    id: eventoDb.id,
    title: eventoDb.titulo,
    start: new Date(eventoDb.inicio),
    end: new Date(eventoDb.fin),
    allDay: eventoDb.todo_el_dia,
    description: eventoDb.descripcion,
    repeat: eventoDb.repetir,
    color: eventoDb.color,
    serie:eventoDb.serie,
    isCompleted: eventoDb.is_completed,
    // Agrega aquí cualquier otro campo relevante
    // Omitir o transformar campos que no sean necesarios para el calendario
  };
}

function transformarEventoCalendarioADb(eventoCalendario) {
  return {
    id: eventoCalendario.id,
    titulo: eventoCalendario.title,
    inicio: eventoCalendario.start.toISOString(), // Asegúrate de convertir a un formato que tu DB entienda
    fin: eventoCalendario.end.toISOString(), // Asegúrate de convertir a un formato que tu DB entienda
    todo_el_dia: eventoCalendario.allDay,
    descripcion: eventoCalendario.description,
    repetir: eventoCalendario.repeat,
    color: eventoCalendario.color,
    serie_id: eventoCalendario.serie ? eventoCalendario.serie.serie_id : null,
    is_completed: eventoCalendario.isCompleted ? eventoCalendario.isCompleted : false
    // Agrega aquí cualquier otro campo relevante
    // Asegúrate de manejar adecuadamente cualquier campo que necesite ser transformado o ajustado.
  };
}



const MiCalendario = ({agenda}) => {
    const { i18n } = useTranslation();
    const [currentMessages, setCurrentMessages] = useState(messagesInEnglish);
    const [events, setEvents] = useState([]); // Estado para los eventos existentes
    const [selectedEvent, setSelectedEvent] = useState(null); 
    const [tempEvent, setTempEvent] = useState(null); // Estado para el evento temporal
    const [modalVisible, setModalVisible] = useState(false); // Estado para mostrar/ocultar el formulario
    const [localizer, setLocalizer] = useState(momentLocalizer(moment));
    const { t } = useTranslation();
    const DraggableCalendar = withDragAndDrop(Calendar);
    const [visibleDate, setVisibleDate] = useState(new Date());
    const [currentView, setCurrentView] = useState('week'); // 
    const [showModal, setShowModal] = useState(false);
    const { isMobile } = useMobileContext();
    const [calendarActive, setCalendarActive] = useState(true); 
    //const [userTimezone, setUserTimezone] = useState("America/Santiago");
    const { timefitUser } = useUserContext();
/*
    useEffect(() => {
      moment.locale(i18n.language);
      setLocalizer(momentLocalizer(moment)); // Actualiza el localizer
    }, [i18n.language]);

    useEffect(() => {
      // Cambia los mensajes del calendario según el idioma actual
      if (i18n.language.startsWith('es')) {
        setCurrentMessages(messagesInSpanish);
      } else {
        setCurrentMessages(messagesInEnglish);
      }
    }, [i18n.language]);*/

    useEffect(() => {
      // Set both locale and timezone based on the user's language
      moment.locale(i18n.language);
      if (timefitUser)
      {
        console.log("zona_horaria usuario:",timefitUser.zona_horaria);
        moment.tz.setDefault(timefitUser.zona_horaria); // Assuming userTimezone is defined
      }
  
      // Update the localizer and messages
      setLocalizer(momentLocalizer(moment));
  
      // Switch calendar messages based on language
      if (i18n.language.startsWith('es')) {
        setCurrentMessages(messagesInSpanish);
      } else {
        setCurrentMessages(messagesInEnglish);
      }
  
    }, [i18n.language, timefitUser]); 

  
    useEffect(() => {
      console.log('useEffect ejecutado');
      const loadEvents = async () => {
      if (timefitUser)
      {
        try {
          console.log('Cargando eventos...',timefitUser.id_usuario);
          const fetchedEvents = await getEvents(timefitUser.id_usuario);
          console.log("fetched events: ",fetchedEvents);
          console.log("usuario:",timefitUser);
          const transformedEvents = fetchedEvents.map(event => {
            const serieObject = event.serie_id ? createSerieObject(event,timefitUser) : null;
            return {
              id: event.id, // Asumiendo que cada evento tiene un 'id'
              title: event.titulo, // Transforma 'titulo' a 'title'
              start: convertirFechaUTCALocal(event.inicio,timefitUser.zona_horaria), //new Date(event.inicio), // Convierte 'inicio' a objeto Date
              end: convertirFechaUTCALocal(event.fin,timefitUser.zona_horaria),//new Date(event.fin), // Convierte 'fin' a objeto Date
              allDay: event.todo_el_dia, // Utiliza 'todo_el_dia' como está
              description: event.descripcion,
              repeat: event.repetir,
              color: event.color,
              serie_id: event.serie_id,
              serie: serieObject,
              isCompleted:event.is_completed,
              // Aquí puedes agregar más transformaciones si son necesarias
              // Omite 'serie_id' y 'estado' si no se usan en el calendario
            };
          });

          const seriesData = await getEvents_series(timefitUser.id_usuario);
          //console.log("Datos con serie activa:", seriesData);
          const calendarEvents = [];
          seriesData.forEach(serie => {
            const serieObject = serie.serie_id ? createSerieObject(serie,timefitUser) : null;
            console.log("serie objects", serieObject);
            const serieEvents = generateSeriesEvents(serie,6,serieObject,serie); // Generar eventos de la serie
            console.log("serie de evntos futuros", serieEvents);
            calendarEvents.push(...serieEvents); // Agregar eventos al calendario
          });
          const combinedEvents = [...transformedEvents, ...calendarEvents];




          setEvents(combinedEvents);
          console.log("Cantidad de eventos obtenidos:", fetchedEvents.length);
        } catch (error) {
          console.log('Error al cargar eventos');
          console.error('Error al cargar eventos:', error);
          // Manejo de errores
        }
      }
      };
  
      loadEvents();
    }, [timefitUser]);

    const handleNavigate = (date, view) => {
      setVisibleDate(date); // Actualiza la fecha visible
      setCurrentView(view); // Actualiza la vista actual
    };

    const handleViewChange = (view) => {
      setCurrentView(view); // Actualiza la vista cuando cambia
    };
/*
    const handleToggleCompleted = async (event,e) => {
      e.preventDefault();
      e.stopPropagation();
      const updatedEvent = { ...event, isCompleted: !event.isCompleted };
      try {
        // Suponemos que tienes una función para actualizar eventos en el backend
        await updateEvent(updatedEvent.id,transformarEventoCalendarioADb(updatedEvent));
        setEvents(currentEvents => 
          currentEvents.map(e => e.id === event.id ? updatedEvent : e)
        );
      } catch (error) {
        console.error("Error al actualizar el evento:", error);
      }
    };*/
    const handleToggleCompleted = async (event, e) => {
      e.preventDefault();
      e.stopPropagation(); // Detiene la propagación para evitar que el clic active el evento del calendario
      const updatedEvent = { ...event, isCompleted: !event.isCompleted };
      try {
        await updateEvent(updatedEvent.id, transformarEventoCalendarioADb(updatedEvent),timefitUser.id_usuario);
        setEvents(currentEvents =>
          currentEvents.map(e => e.id === event.id ? updatedEvent : e)
        );
      } catch (error) {
        console.error("Error al actualizar el evento:", error);
      }
    };
    
    
    
    

    const handleSelectSlot = ({ start, end, action}) => {
      // Configura el evento temporal
      if (action === "select" || action === "click") {
      const newTempEvent = {
        title: t("newEvent"),
        start,
        end,
        allDay: false,
        isNew: true
      };
      setTempEvent(newTempEvent);
  
      // Muestra el formulario
      setModalVisible(true);
      
    }
    };
    useEffect(() => {
      const hideModal = localStorage.getItem('hideModalC');
      if (hideModal !== 'true') {
        setShowModal(true);
      }
    }, []);

    useEffect(()=> {
      if (isMobile===true) {setCurrentView('day')};

    }, [isMobile]);
  
    // eslint-disable-next-line
    const handleUpdateEventCalendar = async (evento_calendario) => {
      try {
          // Suponiendo que `updateEvent` actualiza el evento en la base de datos
          await updateEvent(evento_calendario);
  
          // Actualiza el estado de los eventos
          setEvents(currentEvents => {
              return currentEvents.map(event => {
                  // Si el evento es parte de una serie, actualiza todos los eventos de esa serie
                  if (evento_calendario.serie_id && event.serie_id === evento_calendario.serie_id) {
                      return { ...event, ...{ serie: evento_calendario.serie } };
                  }
                  // Si no es parte de una serie, actualiza sólo el evento específico
                  else if (event.id === evento_calendario.id) {
                      return evento_calendario;
                  }
                  return event;
              });
          });
      } catch (error) {
          console.error('Error al actualizar el evento:', error);
          // Manejo de errores
      }
  };
  const actualizarSerie = (eventPadre,serie_refresh) => {


    // Paso 1: Identificar y remover eventos de la serie específica
    setEvents(currentEvents => currentEvents.filter(event => event.serie_id !== serie_refresh.serieId));
    
    const eventosSerieActualizados = createSerieObject(serie_refresh);

    const serieEvents = generateSeriesEvents(serie_refresh,eventosSerieActualizados,eventPadre);
  
    // Paso 2: Actualizar el evento padre y recrear la serie
    //
  
    // Paso 3: Agregar la serie actualizada al calendario
    setEvents(currentEvents => [...currentEvents, ...serieEvents]);
  };
    
  const handleSaveEvent = async(eventToUpdate) => {
        if (!eventToUpdate.isNew) {
          // Actualizar evento existente
          const updatedEvents = events.map(event => 
            event.id === eventToUpdate.id ? { ...event, ...eventToUpdate } : event
          );
          setEvents(updatedEvents);
        } else {
            //const calendarEvents = [];
            // Agregar un nuevo evento
            let newEvent = { ...eventToUpdate}; // Asegúrate de generar un ID único para nuevos eventos
            //const serieObject = newEvent.serie_id ? createSerieObject(newEvent) : null;
            //newEvent.serie=serieObject;
            console.log('Nuevo Evento en miCalendario',newEvent)
            setEvents([...events, newEvent]);
            if (newEvent.serie && Object.keys(newEvent.serie).length > 0)
            {
              console.log("serie de un evento:",newEvent.serie);
              const SerieActualizados = createSerieObject(newEvent.serie,timefitUser);
              const EventoDB=transformarEventoCalendarioADb(newEvent);
              const serieEvents = generateSeriesEvents(newEvent.serie,6,SerieActualizados,EventoDB); // Generar eventos de la serie
              console.log("serie de nuevos eventos futuros", serieEvents);
              setEvents([...events,...serieEvents]);
              //loadEvents();
            }


        }

        setTempEvent(null); // Eliminar el evento temporal
        setModalVisible(false);
        setTimeout(() => {
          setCalendarActive(true);
        }, 150); 
      };

      const handleUpdateEvent = async (eventToUpdate) => {

        console.log('Comienza handleUpdateEvent.');
        try {
          // si es una serie, me traigo el evento padre de la base de datos.
          if (eventToUpdate.serie){ 
            const evento_padre_db=getEventById(eventToUpdate.id);
            const propiedades_a_copiar=['color','descripcion','titulo','estado']
            for (let propiedad in propiedades_a_copiar){
              evento_padre_db[propiedad]=eventToUpdate[propiedad];

            }
            await updateSerie(eventToUpdate.serie_id,eventToUpdate.serie)
            actualizarSerie(evento_padre_db,eventToUpdate.serie);
            console.log('Intenta actualizar el evento padre de la serie en la BBDD',evento_padre_db);
            await updateEvent(evento_padre_db.id, evento_padre_db,timefitUser.id_usuario);


          }
          else{
            console.log('Intenta actualizar el evento en la BBDD',eventToUpdate);
            await updateEvent(eventToUpdate.id, eventToUpdate,timefitUser.id_usuario);
            const evento_calendario=transformarEventoDbACalendario(eventToUpdate)
          // Actualiza el estado con el evento actualizado sin considerar los eventos con serie.
            setEvents(currentEvents => currentEvents.map(event => event.id === evento_calendario.id ? evento_calendario : event));

          }
          


          
          
          
                    // Actualiza el estado de los eventos
        
        
        } catch (error) {
          console.error('Error al actualizar el evento:', error);
          // Manejo de errores
        }
        setTimeout(() => {
          setCalendarActive(true);
        }, 150); 
      };
      
  
    const handleCancel = (e) => {
      // Elimina el evento temporal y cierra el formulario
      e.stopPropagation();
      setTempEvent(null);

      setModalVisible(false);
      setTimeout(() => {
        setCalendarActive(true);
      }, 150); 
    };

    /*const handleEventDrop = async ({ event, start, end }) => {
      // Aquí puedes implementar la lógica para actualizar el evento en tu estado y opcionalmente en tu backend
      const updatedEvent = { ...event, start, end };
      const updatedEvent2 = transformarEventoCalendarioADb(updatedEvent);
      // Suponiendo que tienes una función para actualizar eventos en el backend
      await updateEvent(updatedEvent2.id,updatedEvent2);
      setEvents(currentEvents => currentEvents.map(evt => evt.id === event.id ? updatedEvent : evt));
    };*/

    const handleEventDrop = async ({ event, start, end }) => {
      try {
        // Aquí puedes implementar la lógica para actualizar el evento en tu estado y opcionalmente en tu backend

        const updatedEvent = { ...event, start, end };
        const updatedEventDb = transformarEventoCalendarioADb(updatedEvent);
        console.log("evento arrastarado a",start,end);
    
        // Suponiendo que tienes una función para actualizar eventos en el backend
        await updateEvent(updatedEventDb.id, updatedEventDb);
    
        // Actualiza el estado de los eventos con el evento actualizado
        setEvents(currentEvents =>
          currentEvents.map(evt => (evt.id === event.id ? updatedEvent : evt))
        );
      } catch (error) {
        console.error("Error al actualizar el evento:", error);
        // Maneja el error según tus necesidades (por ejemplo, muestra un mensaje de error al usuario)
      }
    };
    
    const handleDeleteEvent = async(Initialevent) => {
        const newEvents = events.filter(event => event.id !== Initialevent.id);
        setEvents(newEvents);
        setModalVisible(false);

        try {
          await deleteEvent(Initialevent.id);
          if (Initialevent.serie)
          {
            console.log("Intento de eliminar la serie", Initialevent)
            await deleteSerie(Initialevent.serie_id)
          }
          // Actualiza el estado para reflejar la eliminación
          setEvents(currentEvents => currentEvents.filter(event => event.id !== Initialevent.id));
        } catch (error) {
          console.error('Error al eliminar el evento:', error);
          // Manejo de errores
        }
        setTimeout(() => {
          setCalendarActive(true);
        }, 150); 
      };
  
    const handleEventClick = (event) => {
        setSelectedEvent(event);  
        setModalVisible(true);

        
      };
  
    // Incluye el evento temporal en la lista de eventos si existe
    const allEvents = tempEvent ? [...events, tempEvent] : events;
    const scrollToTime = new Date();
    scrollToTime.setHours(7, 0, 0); // Desplaza inicialmente a las 7:00 AM



  
    return (
      <div>
        <FirstTimeModal 
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title={t("Title Calendar Tutorial")}
        content={t("Content Calendar Tutorial")}
        nameModal='hideModalC'
      />
        {modalVisible && (
          <EventForm
            onSave={handleSaveEvent}
            onCancel={handleCancel}
            onDelete={handleDeleteEvent}
            onUpdate={handleUpdateEvent}
            initialEvent={tempEvent || selectedEvent}
            var_usuario={timefitUser.id_usuario}
          />
        )}
        {(calendarActive ||  !isMobile) &&
        <DraggableCalendar
          localizer={localizer}
          events={allEvents}
          startAccessor="start"
          endAccessor="end"
          selectable
          step={15}
          timeslots={4} 
          onSelectSlot={handleSelectSlot}
          style={{ height: "80vh" }}
          eventPropGetter={eventStyleGetter}
          onDelete={handleDeleteEvent}
          onSelectEvent={handleEventClick}
          onUpdate={handleUpdateEvent}
          defaultView={currentView}
          date={visibleDate}
          scrollToTime={scrollToTime}
          messages={currentMessages}
          onNavigate={handleNavigate}
          onView={handleViewChange}
          onEventDrop={handleEventDrop}
          draggableAccessor={event => !(event.serie && Object.keys(event.serie).length > 0)} // Todos los eventos son arrastrables
          components={{
            event: props => <MyEvent {...props} currentView={currentView} onToggleCompleted={handleToggleCompleted} />,
          }}
        />}
      </div>
    );
  };
  
  export default MiCalendario;
