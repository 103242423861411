// Importaciones necesarias
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, convertFromRaw,ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './TextEditorModal.css'; // Asegúrate de tener un archivo CSS adicional
import htmlToDraft from 'html-to-draftjs';

// Estilos personalizados para el Modal
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '60%', // Tamaño del modal
    maxHeight: '80vh', // Altura máxima
    overflow: 'auto', // Activar scroll
    padding: '20px', // Espaciado adicional para el contenido
    zIndex: 10001 // Establece un valor alto para asegurar que esté por encima
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo oscuro semi-transparente
    zIndex: 10000 // Debe ser más bajo que el contenido pero más alto que el formulario
  }
};

const customStylesMobile = {
  content: {
    top: '1%',
    left: '1%',
    right: '1%',
    bottom: '1%',
    margin: '0',
    padding: '2px',
    borderRadius: '10px',
    border: '1px solid #ccc',
    backgroundColor: '#fff',
    overflow: 'hidden',
    zIndex: 10001,
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 10000,
  }
};

// Componente TextEditorModal
const TextEditorModal = ({ isOpen, onClose, onSave, initialContent }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isMobile, setIsMobile] = useState(false);

    // Check if the screen is small enough to consider it a mobile device
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
      };
      window.addEventListener('resize', handleResize);
      handleResize(); // Initialize on mount
      return () => window.removeEventListener('resize', handleResize);
    }, []);

  // Inicializar el editor con contenido JSON o HTML
  useEffect(() => {
    if (initialContent) {
      try {
        // Intentar interpretar el contenido como JSON primero
        const parsedContent = JSON.parse(initialContent);
        const contentState = convertFromRaw(parsedContent);
        setEditorState(EditorState.createWithContent(contentState));
      } catch (jsonError) {
        // Si no es JSON, interpretarlo como HTML usando `html-to-draftjs`
        try {
          const blocksFromHTML = htmlToDraft(initialContent);
          const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
          setEditorState(EditorState.createWithContent(contentState));
        } catch (htmlError) {
          console.error('Error al interpretar el contenido HTML:', htmlError);
        }
      }
    }
  }, [initialContent]);

  // Cerrar el modal y enviar el contenido actual
  const handleClose = () => {
    const newContent = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    onSave(newContent); // Envía el contenido al padre para que se actualice la variable
    onClose(); // Cierra el editor
  };
    // Contenedor para evitar la propagación de eventos
    const preventPropagation = (event) => {
      event.stopPropagation(); // Evitar que el evento se propague fuera del contenedor
    };

    const toolbarOptions = isMobile
    ? {
        options: ['inline', 'list'],
        inline: {
          options: ['bold', 'italic']
        },
        list: {
          options: ['unordered','ordered']
        }
      }
    : {
      options: [
        'inline', 'blockType', 'fontSize', 'fontFamily',
        'list', 'link', 'emoji', 'history', 'image', 'colorPicker'
      ],
      inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript']
      },
      list: {
        options: ['unordered', 'ordered']
      },
      emoji: {},
      history: {},
      image: {},
      colorPicker: {}
      };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={isMobile ? customStylesMobile : customStyles}>
      <div className="editor-container" onClick={preventPropagation}>
        <Editor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          wrapperClassName="editor-wrapper"
          editorClassName="editor-content"
          toolbar={toolbarOptions}
        />
        <div className="modal-buttons">
          <button onClick={handleClose} className="close-button">Cerrar</button>
        </div>
      </div>
    </Modal>
  );
};

export default TextEditorModal;







