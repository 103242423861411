import supabase from './supabaseClient';

  export const addEvent = async (event) => {
    const { data, error } = await supabase.from('eventos').insert([event]).select();
    if (error) throw error;
    return data[0];
  };
  
  export const getEvents = async (userId) => {
    const { data, error } = await supabase.from('full_eventos_con_series').select('*').eq('user_id', userId); // Filtra los eventos por el ID del usuario
    if (error) throw error;
    return data;
  };

  export const getEvents_series = async (userId) => {
    const { data, error } = await supabase.from('eventos_con_series').select('*').eq('user_id', userId); // Filtra los eventos por el ID del usuario
    if (error) throw error;
    return data;
  };
/*
  export const updateEvent = async (id, updatedEvent) => {
    const { data, error } = await supabase
      .from('eventos')
      .update(updatedEvent)
      .match({ id });
    return { data, error };
  };*/

  const setUserContext = async (userId) => {
    const { error } = await supabase.rpc('set_user_id', { uid: userId });
    if (error) {
      console.error('Error setting user context:', error.message);
    }
  };

  export const updateEvent = async (eventId, updatedEvent, userId) => {
    try {
      // Establecer el contexto del usuario
     // await setUserContext(userId);
  
      console.log('Updating event with ID:', eventId, 'for user:', userId, 'with data:', updatedEvent);
  
      const { data, error } = await supabase
        .from('eventos')
        .update(updatedEvent)
        .eq('id', eventId)
        .eq('user_id',userId);
  
      if (error) {
        console.error('Error updating event:', error.message);
        return { data: null, error };
      }
  
      if (!data || data.length === 0) {
        console.warn('Update not applied. No matching record found or no changes made.');
      } else {
        console.log('Update successful:', data);
      }
  
      return { data, error: null };
    } catch (error) {
      console.error('Unexpected error updating event:', error);
      return { data: null, error };
    }
  };

  export const updateSerie = async (id, updatedSerie) => {
    const { data, error } = await supabase
      .from('series')
      .update(updatedSerie)
      .match({ id });
    return { data, error };
  };



  export const deleteEvent = async (id) => {
    const { data, error } = await supabase
      .from('eventos')
      .delete()
      .match({ id });
    return { data, error };
  };

  export const deleteSerie = async (serie_id) => {
    const { data, error } = await supabase
      .from('series')
      .delete()
      .match({ serie_id });
    return { data, error };
  };

  export const insertSerie = async (serieData) => {
    const { data, error } = await supabase
        .from('series')
        .insert([serieData])
        .single()
        .select();
    if (error) throw error;

    return data;
};

export const insertEventWithSeries = async (eventData, serieData) => {
  try {
      const seriedb1 = await insertSerie(serieData);
      console.log("series obtenida:", seriedb1);
      eventData.serie_id=seriedb1.serie_id
      const eventodb1 = await addEvent(eventData);
      console.log("evento obtenido:", eventodb1);
      return { eventodb1, seriedb1 };
  } catch (error) {
      console.error('Error insertando evento y serie:', error);
      throw error;
  }
};

  // Función para obtener un evento por ID
export const getEventById = async (eventId) => {
  try {
    const { data, error } = await supabase
      .from('eventos') // Asume que tu tabla se llama 'eventos'
      .select('*') // Selecciona todas las columnas o especifica las que necesitas
      .eq('id', eventId) // Filtra por el ID del evento
      .single(); // Devuelve un único registro en lugar de un array

    if (error) throw error;

    return data; // Retorna el evento encontrado
  } catch (error) {
    console.error('Error al obtener el evento:', error);
    throw error; // Propaga el error para manejarlo en el componente que llama a esta función
  }
};