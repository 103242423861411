import React, { useState ,useEffect} from 'react';
import './SerieConfig.css'; // Asegúrate de que la ruta al archivo CSS sea correcta
import { useTranslation } from 'react-i18next';

const daysOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];


const SerieConfig = ({ serieConfig, setSerieConfig,serieStartDate, serieStartTime, serieEndTime }) => {
    const [startDate, setStartDate] = useState(serieStartDate);
    const [startTime, setStartTime] = useState(serieStartTime);
    const [endTime, setEndTime] = useState(serieEndTime);
    const [isExpanded, setIsExpanded] = useState(true);
    const { t } = useTranslation();
    

    


    useEffect(() => {
        if (serieStartDate) {
            setSerieConfig(prevConfig => ({...prevConfig,['startDate']: serieStartDate}));
            setSerieConfig(prevConfig => ({...prevConfig,['startTime']: serieStartTime}));
            setSerieConfig(prevConfig => ({...prevConfig,['endTime']: serieEndTime}));
            setSerieConfig(prevConfig => ({...prevConfig,['repeatType']: 'daily'}));
        }
      }, [serieStartDate]);



    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (type === 'checkbox') {
            setSerieConfig(prevConfig => ({
                ...prevConfig,
                [name]: checked
            }));
        } else {
            setSerieConfig(prevConfig => ({
                ...prevConfig,
                [name]: value
            }));
        }
    };

    const handleDaysChange = (day) => {
        const updatedDays = serieConfig.daysOfWeek.includes(day)
            ? serieConfig.daysOfWeek.filter(d => d !== day)
            : [...serieConfig.daysOfWeek, day];

        setSerieConfig(prevConfig => ({
            ...prevConfig,
            daysOfWeek: updatedDays
        }));
    };
    const toggleExpand = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsExpanded(prevState => !prevState);
    };

    return (
<div className="serie-config">
        <button onClick={toggleExpand} className="toggle-button">
            {isExpanded ? '--' : '+'}
        </button>
        {isExpanded && (
        <div>
        <div className="row">
            <label>
                {t('startDate')}:
                <input
                    type="date"
                    name="startDate"
                    required
                    value={serieConfig.startDate}
                    readOnly
                />
            </label>

            <label>
                {t('endDate')}:
                <input
                    type="date"
                    name="endDate"
                    required
                    min={serieConfig.startDate}
                    value={serieConfig.endDate || ''}
                    onChange={handleChange}
                />
            </label>
        </div>

        <div className="row">
            <label>
                {t('startTime')}:
                <input
                    type="time"
                    name="startTime"
                    required
                    value={serieConfig.startTime}
                    onChange={handleChange}
                />
            </label>

            <label>
                {t('endTime')}:
                <input
                    type="time"
                    name="endTime"
                    required
                    min={serieConfig.startTime}
                    value={serieConfig.endTime || ''}
                    onChange={handleChange}
                />
            </label>
        </div>

        <label>
            {t('numberOfRepetitions')}:
            <input
                type="number"
                name="repeatCount"
                value={serieConfig.repeatCount || 1}
                min="1"
                onChange={handleChange}
            />
        </label>

        <label>
            {t('repeatType')}:
            <select name="repeatType" value={serieConfig.repeatType || 'daily'} onChange={handleChange}>
                <option value="daily">{t('daily')}</option>
                <option value="weekly">{t('weekly')}</option>
                <option value="monthly">{t('monthly')}</option>
                <option value="yearly">{t('yearly')}</option>
            </select>
        </label>

        <label>
            {t('interval')}:
            <input
                type="number"
                name="interval"
                value={serieConfig.interval || 1}
                min="1"
                onChange={handleChange}
            />
        </label>
        <div className="dow-selector">
            {daysOfWeek.map(day => (
                <label key={day} className="dow-checkbox">
                    <input
                        type="checkbox"
                        name="daysOfWeek"
                        checked={serieConfig.daysOfWeek?.includes(day)}
                        onChange={() => handleDaysChange(day)}
                    />
                    {t(day)}
                </label>
            ))}
            
        </div>
        </div>
        )}
    </div>

     
    );
};

export default SerieConfig;
