import React, { useState,useEffect} from 'react';
import styled from 'styled-components';
import {fetchTags} from '../api/tasksApi';

/*
const TagSelector = styled.div`
  display: inline-flex;
  margin: 5px;
  padding: 5px 10px;
  border-radius: 15px;
  background-color: ${props => props.color || '#DDD'};
  color: white;
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.7;
  }
`;*/

const TagSelector = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 2px;
  padding: 3px 8px;
  border-radius: 12px;
  background-color: ${props => props.color || '#DDD'};
  color: white;
  cursor: pointer;
  font-size: 10px;  // Tamaño de fuente reducido
  user-select: none;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    opacity: 0.85;
    transform: scale(1.3);  // Ligeramente más grande al pasar el mouse para mejor feedback
  }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  margin-top: 10px;
`;

// Suponiendo que tienes una lista de tags disponibles
// Cada tag en `availableTags` debe tener `id`, `name` y `color`
const availableTagsDefecto = [
  { id: 1, name: 'Trabajo/estudios_defecto', color: '#4A90E2' },
  { id: 2, name: 'Salud y Bienestar', color: '#7ED321' },
  { id: 3, name: 'Hogar', color: '#F5A623' },
  { id: 4, name: 'Familia y Amigos', color: '#50E3C2' },
  { id: 5, name: 'Finanzas', color: '#F8E71C' },
  { id: 6, name: 'Compras', color: '#D0021B' },
  { id: 7, name: 'Ocio y Entretenimiento', color: '#9013FE' },
  { id: 8, name: 'Deportes y Actividad Física', color: '#4A4A4A' },
  { id: 9, name: 'Viajes', color: '#22B573' },
  { id: 10, name: 'Desarrollo Personal', color: '#BD10E0' },
  { id: 11, name: 'Compromisos y Eventos', color: '#FF7F00' },
  { id: 12, name: 'Voluntariado y Causas', color: '#417505' } 
  // Agrega más tags aquí
];

// Componente para la selección de tags
export const TagSelectorComponent = ({ selectedTags, setSelectedTags, userId = null }) => {

  const [availableTags, setAvailableTags] = useState(availableTagsDefecto);
  useEffect(() => {
    const loadTags = async () => {
        const tags = await fetchTags(userId);
        console.log("tags: ",tags);
        setAvailableTags(tags);
    };

    loadTags();
}, [userId]);

  const toggleTag = (tagId) => {
    if (selectedTags.includes(tagId)) {
      setSelectedTags(selectedTags.filter(id => id !== tagId));
    } else {
      setSelectedTags([...selectedTags, tagId]);
    }
  };

  return (
    <TagsContainer>
      {availableTags.map(tag => (
        <TagSelector
          key={tag.tag_id}
          color={tag.color}
          onClick={() => toggleTag(tag.tag_id)}
          style={{ 
            backgroundColor: selectedTags.includes(tag.tag_id) ? tag.color : '#DDD',
            color: selectedTags.includes(tag.tag_id) ? 'white' : '#000',
          }}
        >
          {tag.tag_name}
        </TagSelector>
      ))}
    </TagsContainer>
  );
};