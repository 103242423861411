import styled from 'styled-components';

// Creando un botón estilizado que acepta una prop "active"
const LanguageButton = styled.button`
  background-color: ${(props) => (props.active ? '#4CAF50' : 'white')};
  color: ${(props) => (props.active ? 'white' : 'black')};
  border: ${(props) => (props.active ? '2px solid black' : '2px solid #ddd')};
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;

  &:hover {
    background-color: ${(props) => (props.active ? '#45a049' : '#f1f1f1')};
    border-color: black;
  }
`;

export default LanguageButton;
