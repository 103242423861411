import React, { useState, useEffect,useCallback } from 'react';
import Panel from './Panel'
import styled from 'styled-components';
import {getTaskElements,obtenerHistorialChat} from '../api/tasksApi';
import ChatPopup from './ChatPopup';
import { useTranslation } from 'react-i18next';
import FirstTimeModal from '../Documentation/FirstTimeModal.js';
// Definiendo el contenedor del TaskDriver
const TaskDriverContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh; // Ocupa toda la altura de la ventana
`;

const Button = styled.button`
  padding: 10px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
`;

const TaskTitle = styled.h2`
  font-size: 24px; // Ajusta el tamaño de la fuente según tus necesidades
  color: #333; // Elige un color que se ajuste a tu paleta
  padding: 10px 20px;
  background-color: #f9f9f9; // Un fondo sutil, cambia si es necesario
  border-radius: 8px; // Bordes redondeados
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); // Sombra ligera para dar profundidad
  margin: 10px 0; // Espacio arriba y abajo
  text-align: center; // Centrar el texto
`;



const TaskDriver = ({ tasks,TaskID,user }) => {
  const [selectedTaskId, setSelectedTaskId] = useState(TaskID);
  const [task, setTask] = useState(null);
  const [tasksD, setTasksD] = useState(tasks);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingHist, setIsLoadingHist] = useState(true);
 // const [isLoadingTask, setIsLoadingTask] = useState(false);
 // const [taskDictionary, setTaskDictionary] = useState({});
  const [elements,setElements] = useState([])
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  //const [historialChats, setHistorialChats] = useState([]);

  /*useEffect(() => {
      setIsLoadingHist(true);
      const cargarHistorial = async () => {
          if(user) {
              const historial = await obtenerHistorialChat(user.sub);
              console.log("hitorial con User: ",historial,user,user.sub);
              setHistorialChats(historial);
              console.log("hitorialChat con User: ",historialChats,user,user.sub);
              if (historialChats) setIsLoadingHist(false);
          }
      };

      cargarHistorial();

  }, [user]);*/

  const openTask = useCallback(async (taskId) => {
    // Aquí va tu lógica para abrir una tarea existente
    // Por ejemplo, podrías cargar los detalles de la tarea y luego establecer el estado con esos detalles
    const taskDetails = await getTaskElements(taskId);
    setElements(taskDetails)
    //setTask(taskDetails);
  }, []);

  useEffect(() => {
    const hideModal = localStorage.getItem('hideModalT');
    if (hideModal !== 'true') {
      setShowModal(true);
    }
  }, []);
/*
  async function findTaskById(taskId) {
    // Suponiendo que fetchTasks es una función asíncrona que obtiene las tareas
    //const tasks = await fetchTasks(); // Espera a que las tareas se carguen
    console.log("corriendo el find....");
    return tasks.find(task => task['id'] === taskId); // Busca y retorna la tarea específica
  }
  
  useEffect(() => {
    // Convierte el arreglo de tareas en un diccionario
    const createTaskDictionary = () => {
      const dictionary = {};
      tasks.forEach((task) => {
        dictionary[task.id] = task; // Asigna la tarea al ID correspondiente
      });
      return dictionary;
    };

    // Actualiza el estado con el diccionario de tareas
    setTaskDictionary(createTaskDictionary());
  }, [tasks]);*/
/*
  const actualizarTareas = (nuevoTask) => {

    setTasksD([...tasksD, nuevoTask]);
    setTask(nuevoTask);
    //setSelectedTaskId(nuevoTask.id);
    console.log("tareas al actualizar: ",tasksD,nuevoTask);
    // Aquí puedes añadir nuevoTask a tu estado o base de datos según necesites
  };
*/

/*const actualizarTareas = (nuevoTask,deletetaskid=null) => {
  // Verifica si el nuevoTask ya existe en la lista basándose en el id
  const tareaExistenteIndex = tasksD.findIndex(tarea => tarea.id === nuevoTask.id);

  if (tareaExistenteIndex !== -1) {
    // Si la tarea ya existe, reemplázala
    const nuevasTareas = tasksD.map((tarea, index) => 
      index === tareaExistenteIndex ? nuevoTask : tarea
    );
    setTasksD(nuevasTareas);
  } else if (!deletetaskid) {
    // Si la tarea no existe, simplemente añádela a la lista
    setTasksD(prevTasks => [...prevTasks, nuevoTask]);
  }
  else{

  }

  // Actualiza la tarea actual y el id seleccionado
  setTask(nuevoTask);
  //setSelectedTaskId(nuevoTask.id);
  console.log("Tareas al actualizar: ", tasksD, nuevoTask);
};*/

const actualizarTareas = (nuevoTask, deleteTaskId = null) => {
  if (deleteTaskId) {
    // Elimina la tarea del estado basado en deleteTaskId
    const tareasActualizadas = tasksD.filter(tarea => tarea.id !== deleteTaskId);
    setTasksD(tareasActualizadas);
    // Si la tarea eliminada es la que está actualmente seleccionada, resetea la selección
    console.log("seleccionada y borrada:",selectedTaskId,deleteTaskId);
    if (Number(deleteTaskId) === Number(selectedTaskId)) {
      console.log("deja la tarea en null");
      setTask(null);
      setSelectedTaskId(null);
    }
  } else if (nuevoTask) {
    // Verifica si el nuevoTask ya existe en la lista basándose en el id
    const tareaExistenteIndex = tasksD.findIndex(tarea => tarea.id === nuevoTask.id);
    if (tareaExistenteIndex !== -1) {
      // Si la tarea ya existe, reemplázala
      const nuevasTareas = tasksD.map((tarea, index) => 
        index === tareaExistenteIndex ? nuevoTask : tarea);
      setTasksD(nuevasTareas);
    } else {
      // Si la tarea no existe, simplemente añádela a la lista
      setTasksD(prevTasks => [...prevTasks, nuevoTask]);
    }

    // Actualiza la tarea actual y el id seleccionado
    setTask(nuevoTask);
    setSelectedTaskId(nuevoTask.id);
  }

  console.log("Tareas al actualizar: ", tasksD, nuevoTask);
};

  useEffect(() => {

    // Simula la carga de la tarea y sus elementos al cambiar selectedTaskId
    const loadTask = async () => {
      setIsLoading(true);
        setTasksD(tasksD);

      // Aquí, carga los detalles de la tarea y actualiza el estado 'task'
      /*const newTask = tasks.find(t => t.id === selectedTaskId);
      console.log("Nuevo Task a asignar:", newTask,tasks);
      
      if (newTask) {
        console.log("setTask: ",newTask);
        setTask(newTask);
      }*/
      try {
      const newTask=tasksD.find(t => t.id === Number(selectedTaskId));

      if (newTask) {
        setTask(newTask)
        console.log("new Task: ",newTask);
      }
      else { console.log("No se encontraron detalles o elementos para la tarea con id:", selectedTaskId,tasksD);}
      setIsLoading(false);
      }catch (error) {
        console.error("Error al cargar los detalles de la tarea:", error);
        // Manejo del error
      } finally {
        setIsLoading(false);
      }

      // Luego, carga los elementos asociados a esta tarea y actualiza el estado 'elements'
      //const newElements = await getTaskElements(selectedTaskId);
      //setElements(newElements);
    };

    if (selectedTaskId) {
      console.log("entró al use effect con taskId de TaskDriver ",selectedTaskId);
      loadTask();
    } else {
      console.log("entró al use effect sin taskId de TaskDriver ",selectedTaskId);
      // Reset cuando no hay una tarea seleccionada
      setTask({ titulo: '', descripcion: '' });
      setElements([]);
      setIsLoading(false);
      //if (historialChats) setIsLoadingHist(false);
  }
  }, [selectedTaskId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTask(prevTask => ({ ...prevTask, [name]: value }));
  };

  const handleChangeTask = (e) => {
    const id = e.target.value;
    setSelectedTaskId(id); // Actualiza el ID de la tarea seleccionada
  
    // Encuentra la tarea basada en el ID seleccionado
    const selectedTask = tasksD.find(t => t.id === parseInt(id));
    
    // Actualiza el estado de la tarea seleccionada
    setTask(selectedTask);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //onTaskUpdate(task);
  };
  if (isLoading) {
    return <div>Cargando...</div>;
}

  return (
    <>
    <FirstTimeModal 
    isOpen={showModal}
    onClose={() => setShowModal(false)}
    title={t("Title TaskManager Tutorial")}
    content={t("Content TaskManager Tutorial")}
    nameModal='hideModalT'
  />
    <TaskDriverContainer>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    {/*<select value={selectedTaskId || ''} onChange={handleChangeTask}>
      {!selectedTaskId &&
        <option value="">{t('selectTask')}</option>
        }
      {tasksD?.map((t) => (
        <option key={t.id} value={t.id}>{t.titulo}</option>
     ))}
      </select>*/}
            {selectedTaskId ? (
        <TaskTitle>{task.titulo}</TaskTitle> // Usa el título de la tarea
      ) : (
        <TaskTitle>Ninguna tarea seleccionada</TaskTitle> // Mensaje por defecto
      )}
    </div>
      {/* Solo renderiza Panel si selectedTaskId y task están definidos */}
      {!isLoading &&
        <Panel selectedTask_Id={selectedTaskId} task_i={task} user={user} actualizarTareas={actualizarTareas}></Panel>
      }
  {!isLoading && 
    <ChatPopup user={user} task={task} />
}
  </TaskDriverContainer>
  </>
  );
};

export default TaskDriver;