// MobileContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';

// Create a context
const MobileContext = createContext();

// Context Provider
export const MobileProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);

  // Check if the screen size is less than or equal to 768px
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initialize on component mount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <MobileContext.Provider value={{ isMobile }}>
      {children}
    </MobileContext.Provider>
  );
};

// Custom hook to consume the mobile context
export const useMobileContext = () => useContext(MobileContext);
