import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  width: 80%;
  max-width: 500px;
`;

const ModalHeader = styled.h2`
  margin-top: 0;
`;

const ModalContent = styled.p`
  line-height: 1.6;
`;

const CheckboxContainer = styled.label`
  display: block;
  margin-top: 20px;
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const FirstTimeModal = ({ isOpen, onClose, title, content,name_modal }) => {
  const handleCheckbox = (e) => {
    localStorage.setItem(name_modal, e.target.checked);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Overlay onClick={onClose}>
      <ModalContainer onClick={e => e.stopPropagation()}>
        <ModalHeader>{title}</ModalHeader>
        <ModalContent dangerouslySetInnerHTML={{__html: content}}>
        {/*<dangerouslySetInnerHTML={{__html: content}} />*/}
        </ModalContent>
        <CheckboxContainer>
          <input type="checkbox" onChange={handleCheckbox} />
          No mostrar esto nuevamente
        </CheckboxContainer>
        <Button onClick={onClose}>Cerrar</Button>
      </ModalContainer>
    </Overlay>
  );
};

export default FirstTimeModal;
