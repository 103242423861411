import React, { useState, useEffect, useRef } from 'react';
import { Rnd } from 'react-rnd';
//import DraggableResizable from 'react-draggable-resizable';
//import 'react-draggable-resizable/lib/style.css';
import ReactQuill from 'react-quill';
import styled from 'styled-components';
import { FiTrash2,FiX } from 'react-icons/fi';
import './custom_quill.css';

// Ajustar posición en función del zoom
const adjustPositionWithZoom = (position, zoomlevel) => ({
  x: position.x * zoomlevel,
  y: position.y * zoomlevel,
});

const modules = {
    toolbar: [
      [{ 'font': [] }], // Dropdown con fuentes
      [{ 'size': ['small', false, 'large', 'huge'] }], // Dropdown con tamaños
      ['bold', 'italic', 'underline'], // Botones para negrita, cursiva y subrayado
      [{ 'color': [] }, { 'background': [] }], // Dropdowns para color de texto y de fondo
      ['clean'] // Botón para limpiar el formato
    ]
  };
  

// Ajustar tamaño en función del zoom
const adjustSizeWithZoom = (size, zoomlevel) => ({
  width: size.width * zoomlevel,
  height: size.height * zoomlevel,
});

// Componente EditableContainer ajustado con propiedades de zoom
const EditableContainer = styled.div`
  position: relative;
  width: 100%; // Usamos 100% para mantener la escala basada en el contenedor padre
  height: 100%; // Mantenemos el contenedor ajustado al contenido interno
`;



const DeleteButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10; // Asegúrate de que el z-inde
  background: red;
  color: white;
  border: none;
  cursor: pointer;
  display: ${({ isediting }) => isediting ? 'block' : 'none'};
`;

const CloseButton = styled(DeleteButton)`
  right: auto; // Ajusta según necesites para posicionar correctamente
  left: 0px
  z-index: 10; // Asegúrate de que el z-inde
  background: gray;
  display: ${({ isediting }) => isediting ? 'block' : 'none'};
`;

const TextDisplay = styled.div`
  font-size: ${({ fontsizebase, zoomlevel }) => `${fontsizebase * zoomlevel}px`};
  padding: 12px 15px;
`;
const PostItContainer = styled.div`
  background-color: ${props => props.color || 'yellow'};
  padding: 10px;
  font-size: ${props => `${props.fontSize * props.zoomlevel}px`};
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1); // Borde sutil para definición
  // Simular "sombra" con un borde más oscuro en la parte inferior y derecha
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);

  overflow: hidden;
  position: relative;
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
`;


const PostItEdit = styled.textarea`
  background-color: ${(props) => props.color || 'yellow'};
  width: 100%;
  height: 100%;
  border: none;
  padding: 10px;
  font-size: ${(props) => props.fontSize * props.zoomlevel}px;
  box-sizing: border-box;
  resize: none;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.3); // Agrega sombra
  transform: rotate(1deg); // Rota ligeramente para un efecto más natural
  border: 1px solid #e5e5e5; // Opcional: agrega un borde sutil
`;


// Componente principal EditableElement
const EditableElement = ({ element, onUpdate, onDelete, zoomlevel,id }) => {
  const [content, setContent] = useState(element.content);
  const [isEditing, setIsEditing] = useState(false);
  const elementRef = useRef(null);
  const quillRef = useRef(null);
  const [refreshFontSize, setRefreshFontSize] = useState(0);
  

  const quillStyles = {
    height: '100%',
    width: '100%',
  };
/*
  // Desactivar edición al clic fuera
  useEffect(() => {
    const handleClickOutsidebk = (event) => {
   //     if (!elementRef.current.contains(event.target) && !event.target.closest('.ql-toolbar')) {
    //    setIsEditing(false);
    //  }
      if (elementRef.current && !elementRef.current.contains(event.target)) {
    setIsEditing(false);
      }
    };

    const handleClickOutside = (event) => {
        let targetElement = event.target; // Empezar con el elemento en el que se hizo clic
    
        // Buscar en los padres del elemento hasta encontrar uno que coincida con los controles de ReactQuill o con el selector de color
        while (targetElement != null) {
          if (targetElement === quillRef.current ||targetElement === elementRef.current || targetElement.classList.contains('ql-formats')) {
            // Si se hizo clic dentro de ReactQuill o en el selector de color, salir y no hacer nada
            return;
          }
          targetElement = targetElement.parentElement;
        }
    
        // Si se llega aquí, se hizo clic fuera de ReactQuill y del selector de color
        setIsEditing(false);
      };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
*/
  useEffect(() => {
    // Ajustar el tamaño de fuente de .ql-editor basado en el nivel de zoom
    const styleSheetId = 'custom-quill-font-size';
    let styleSheet = document.getElementById(styleSheetId);
    
    const adjustedFontSize = element.fontSize * zoomlevel;
  
    if (!styleSheet) {
      // Crear una nueva hoja de estilo si no existe
      styleSheet = document.createElement('style');
      styleSheet.id = styleSheetId;
      styleSheet.type = 'text/css';
      document.head.appendChild(styleSheet);
    }
    
    // Actualizar el contenido de la hoja de estilo
    styleSheet.innerText = `.ql-editor { font-size: ${adjustedFontSize}px !important; }`;
  
  }, [zoomlevel,refreshFontSize]);

  // Función para manejar el doble clic
    const handleDoubleClick = () => {
    setIsEditing(true);
    // Forzar la actualización del tamaño de fuente
    setRefreshFontSize(prev => prev + 1);
    setTimeout(() => {
      if (quillRef.current) {
        quillRef.current.focus();
      }
    }, 100);
  };

  const onDragStop = (e, d) => {
    // Calcula la posición ajustada
    console.log("arrastrando:",e,d);
    const adjustedPosition = {
      x: d.x / zoomlevel,
      y: d.y / zoomlevel,
    };
    
    // Actualiza la posición del elemento con la posición ajustada
    console.log("new position:",adjustedPosition);
    onUpdate(element.id, adjustedPosition, element.size, content);
  };
  
  const onResizeStop = (e, direction, ref, delta, position) => {
    //setIsDragging(false);

    const adjustedPosition = {
        x: position.x / zoomlevel,
        y: position.y / zoomlevel,
      };
    // Calcula el tamaño ajustado
    const newSize = {
      width: ref.offsetWidth / zoomlevel,
      height: ref.offsetHeight / zoomlevel,
    };
    
    // Actualiza tanto la posición como el tamaño del elemento
    onUpdate(element.id, adjustedPosition, newSize, content);
  };



  return (
   <Rnd
      size={adjustSizeWithZoom(element.size, zoomlevel)}
      position={adjustPositionWithZoom(element.position, zoomlevel)}
      onDragStop={onDragStop}
      onResizeStop={onResizeStop}
      bounds="parent"
      disableDragging={isEditing}
      enableResizing={{
        top: true, right: true, bottom: true, left: true,
        topRight: false, bottomRight: false, bottomLeft: false, topLeft: false
      }}
    >

   <EditableContainer ref={elementRef} className="editable-container">
        {isEditing && (
             <>
          <DeleteButton onClick={() => onDelete(element.id)} isediting="true">
            <FiTrash2 />
          </DeleteButton>
          <CloseButton onClick={() => { 
            setIsEditing(false);
            // Construye el objeto de elemento actualizado con el nuevo contenido
            const updatedElement = {
              ...element,
              content: content,
            };
            onUpdate(updatedElement.id, updatedElement.position, updatedElement.size, content);
          }} isediting="true">
            <FiX />
            </CloseButton>
          </>
        )}
        {element.type === 'text' && (
          
          isEditing ? (
            <ReactQuill 
              ref={quillRef} 
              value={content} 
              onChange={setContent}
              modules={modules} 
              style={quillStyles}
            />
          ) : (
            <TextDisplay fontsizebase={element.fontSize} zoomlevel={zoomlevel} onDoubleClick={handleDoubleClick} dangerouslySetInnerHTML={{ __html: content }} id={id} />
            )
          


            )}

            {/*<TextDisplay fontsizebase={element.fontSize} zoomlevel={zoomlevel} onDoubleClick={handleDoubleClick} dangerouslySetInnerHTML={{ __html: content }} id={id} />*/}
          
        
      {element.type === 'image' && (
      isEditing ? (
        // Aquí podrías incluir un input para cambiar la imagen si lo deseas

        <StyledImage src={content} alt="Editable" draggable = {false} style={{ width: '100%' , height: 'auto' }} id={id} />

      ) : (
        <StyledImage src={content} alt="Editable" draggable = {false} style={{ width: '100%', height: 'auto' }} onDoubleClick={() => setIsEditing(true) } id={id}  />
      )
    )}

    {/*element.type === 'postit' && (
      <div
        style={{
          backgroundColor: element.color || 'yellow', // Fallback a amarillo si no hay color
          padding: '10px',
          fontSize: `${element.fontSize * zoomlevel}px`,
          width: '100%',
          height: '100%',
          boxSizing: 'border-box', // Asegura que el padding no afecte las dimensiones externas
        }}
        onDoubleClick={() => setIsEditing(true)}
        dangerouslySetInnerHTML={{ __html: content }}
        id={id}
      />
      )*/}
{element.type === 'postit' && (
  isEditing ? (
    <PostItEdit
      color={element.color}
      fontSize={element.fontSize}
      zoomlevel={zoomlevel}
      value={content}
      onChange={(e) => setContent(e.target.value)}
      onBlur={() => setIsEditing(false)}
    />
  ) : (
    <PostItContainer
      color={element.color}
      fontSize={element.fontSize}
      zoomlevel={zoomlevel}
      onDoubleClick={() => setIsEditing(true)}
      dangerouslySetInnerHTML={{ __html: content }}
      id={id}
    />
  )
)}

        {/* Implementación para tipos 'image' y 'postit' como sea necesario */}
      </EditableContainer>
   </Rnd>
  );
};

export default EditableElement;
